import $ from "jquery";
import React from 'react';
import createReactClass from 'create-react-class';

var CHelper = createReactClass({
	componentDidMount: function(){
		//TODO(mujibur): no need to use jquery here
		// $("[data-toggle=popover]").popover();
		// $('body').on('click', function (e) {
		// 	$('[data-toggle="popover"]').each(function () {
		// 		if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
		// 			$(this).popover('hide');
		// 		}
		// 	});
		// });
	},
	render: function(){
		return(
			<button className="hintTextClass btn btn-success btn-xs dropdown-toggle" 
				data-container="body" 
				data-toggle="popover" 
				data-placement={this.props.pos} 
				data-html="true" 
				data-content={this.props.previewMessage}
				type="button">
				<i className="fa fa-question"></i>
			</button>
		);
	}
});
export default CHelper;