import React from 'react';
import createReactClass from 'create-react-class';

var CentionPopup = createReactClass({
	getInitialState: function() {
		return {
			selectedValues: [],
			selectedValue: ''
		};
	},
	getDefaultProps: function(){
		return{
			multiple: '',
			selectedItems: []
		}
	},
	componentDidMount: function(){
		this.pid=setTimeout(function(){
			this.setState({
				selectedValues: this.props.selectedItems
			});
		}.bind(this), 50);
	},
	componentWillUnmount: function(){
		clearTimeout(this.pid);
	},
	handleChange: function( e ){
		e.preventDefault();
		var options = e.target.options;
			var values = [];
			for (var i = 0, l = options.length; i < l; i++) {
			if (options[i].selected) {
				values.push(options[i].value);
			}
			}
		if(this.props.multiple){
			this.setState({selectedValues: values});
			this.props.onChange(values);
		}
		else{
			this.setState({selectedValue: values});
			this.props.onChange(values);
		}
	},
	render: function(){
		var lists = this.props.src;
		var defaultValue;
		if(lists && lists.length > 0) {
			var options = lists.map(function(opt, i){
				if(opt.separator == undefined || opt.separator == false)
					return <option key={i} value={opt.id}>{opt.value}</option>
				else
					return <option disabled style={{fontWeight: 'bold', color: '#79d9f7'}} key={i} value={opt.id}>{opt.value}</option>
			}, this);
			this.props.selectedItems.map(function(item, i){
				if( this.props.multiple ){
					if( defaultValue === undefined ){
						defaultValue = [];
					}
					defaultValue.push(item);
				}else{
					defaultValue = item;
				}
			}, this);
			var firstOption = (this.props.firstItem !== undefined && this.props.firstItem !== "" && lists.length > 0 ? <option style={{backgroundColor: '#79d9f7'}} key={"0"} value="0">{this.props.firstItem}</option> : (lists.length <= 0 ? <option style={{backgroundColor: '#79d9f7'}} disabled key={"0"} value="0">{I("No Items")}</option> : ''));
		}
		var selectStyle="", selectStyleDiv="";
		if(this.props.pageSrc=="admin") {
			selectStyleDiv="";
			selectStyle="selectpicker show-tick form-control input-sm";
		}
		else {
			selectStyleDiv="select-style";
			selectStyle="";
		}
		var disabled = "";
		if (this.props.disabled != undefined && !this.props.disabled )
			disabled = "disabled";
		return(
			<div className={selectStyleDiv}>
			<select
				id={this.props.id}
				name={this.props.name}
				ref={this.props.id}
				defaultValue={defaultValue}
				multiple={this.props.multiple}
				onChange={this.handleChange}
				className={selectStyle}
				disabled={disabled}
				data-qa-id={this.props["data-qa-id"]}
			>
				{firstOption}
				{options}
			</select>
			</div>
		);
	}
});
export default CentionPopup;