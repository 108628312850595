import $ from "jquery";
import React, { memo, useEffect, useCallback, useState, Fragment } from 'react';
import each from 'lodash/each';
import classNames from "classnames";
import createReactClass from 'create-react-class';
import TableButton from './TableButton';
import DropDown from './DropDown';
import { ActionsWrapper, Delete, Edit, CustomAdd } from '../reactcomponents/Admin'
import SwitchCheckbox from '../reactcomponents/SwitchCheckbox';

// import { humanByteSize } from '../common/v5/helpers.js';

var itemRowPfx = 'itemRow_';

var TableRowCtrlCheckbox = createReactClass({
	handleCtrlCheckbox: function (e) {
		if (this.props.onCtrlCheckboxChange) {
			var k = this.props.row,
				row = parseInt(k.substr(k.indexOf(itemRowPfx) +
					itemRowPfx.length), 10);
			this.props.onCtrlCheckboxChange(row, this.props.col,
				e.target.checked);
		}
	},
	render: function () {
		return (
			<input type="checkbox" checked={this.props.checked}
				onChange={this.handleCtrlCheckbox} />
		);
	}
});

var TableRow = createReactClass({
	getInitialState: function () {
		return {
			display: "none",
			tooltipHover: false
		}
	},
	actionUrl: function (url, data) {
		var regExp = /\{([^}]+)\}/g;
		var matches = url && url.match(regExp);
		if (matches) {
			for (var i = 0; i < matches.length; i++) {
				var str = matches[i];
				var c = str.substring(1, str.length - 1);
				for (var item in data) {
					if (item == c) {
						url = url.replace(str, data[item]);
					}
				}
			}
		}
		return url;
	},
	checkboxHandleChange: function (e) {
		this.props.onSelect(this.refs.selector.id, !this.props.selected);
	},
	_rowClickEvent: function (event) {
		var data = this.props.data;
		var rowUrl = this.props.controllerName;
		if (rowUrl) {
			var url = this.actionUrl(rowUrl, data);
			location.href = url;
		} else if (typeof this.props.altOnClickHandler === 'function') {
			this.props.altOnClickHandler(this.props.data);
		} else if (this.props.rowHighlight) {
			let thisId = data.id ? data.id : data.Id;
			this.props.rowHighlight(thisId, data.read)
		} else {
			event.stopPropagation();
		}
	},
	onActionRow: function (actiondata) {
		if (this.props.onActionRow) {
			var data = this.props.data;
			var requestUri = actiondata.action;
			if (actiondata.hasOwnProperty("requestParams")) {
				if (actiondata.requestParams) {
					var params = decodeURIComponent($.param(actiondata.requestParams));
					requestUri = actiondata.action + "?" + params;
				}
			}
			this.props.onActionRow(this.actionUrl(requestUri, data), data);
		}
	},
	onCopyRow: function (event) {
		if (this.props.onCopyRow) {
			this.props.onCopyRow(event)
		}
	},
	onEditRow: function (event) {
		if (this.props.onEditRow) {
			this.props.onEditRow(this.props.data.id);
		}
	},
	onEditActionRow: function (event) {
		if (this.props.onEditActionRow) {
			this.props.onEditActionRow(this.props.data.id, this.props.data);
		}
	},
	onAddActionRow: function (event) {
		if (this.props.onAddActionRow) {
			this.props.onAddActionRow(this.props.data.id);
		}
	},
	onDeleteRow: function (event) {
		if (this.props.onDeleteRow) {
			this.props.onDeleteRow(this.props.data.id);
		}
	},
	handleButtonClick: function (action) {
		this.props.onDeleteAction(action);
	},
	handleDragStart: function (event) {
		var id = '#' + itemRowPfx + this.props.data.id + ' > #' + event.currentTarget.id + ' .previewer';
		$(id).css('display', 'none');
		if (this.props.onDragStart) {
			this.props.onDragStart(event);
		}
	},
	_rowHoverEvent: function (event) {
		var id = "#" + event.currentTarget.id;
		if (this.props.onHover) {
			if (this.props.data.parentId) {
				$("#" + itemRowPfx + this.props.data.parentId + "_" + this.props.data.id + " > " + id + " .previewer").css('display', 'block');
			} else {
				var rowId = 0;
				if (typeof this.props.data.id !== "undefined") {
					rowId = this.props.data.id;
				} else if (typeof this.props.data.Id !== "undefined") {
					rowId = this.props.data.Id;
				}
				$("#" + itemRowPfx + rowId + " > " + id + " .previewer").css('display', 'block');
			}
		}
	},
	_rowHoverOutEvent: function (event) {
		var id = "#" + event.currentTarget.id;
		if (this.props.hoverOut) {
			this.props.hoverOut(this.props.data.id);
		}
		if (this.props.data.parentId) {
			$("#" + itemRowPfx + this.props.data.parentId + "_" + this.props.data.id + " > " + id + " .previewer").css('display', 'none');
		} else {
			var rowId = 0;
			if (typeof this.props.data.id !== "undefined") {
				rowId = this.props.data.id;
			} else if (typeof this.props.data.Id !== "undefined") {
				rowId = this.props.data.Id;
			}
			$("#" + itemRowPfx + rowId + " > " + id + " .previewer").css('display', 'none');
		}
	},
	tooltipHandleMouseIn: function () {
		this.setState({ tooltipHover: true })
	},
	tooltipHandleMouseOut: function () {
		this.setState({ tooltipHover: false })
	},
	_rowExpandEvent: function (event) {
		this.props.onChildExpand(this.props.data.id, this.props.childExpand);
		event.stopPropagation();
	},
	createDropDownOrButton: function (c, i, item) {
		if (c.key && item[c.key] == "button") {
			var data = c.data;
			return <td width="25%" key={"button" + i}><TableButton key={"activateBtn_" + i} onButtonClick={this.handleButtonClick} action={this.actionUrl(data.buttonAction.action, item)} src={data.buttonAction.src} buttonName={data.buttonAction.name} color={data.color} actionType={data.buttonAction.actionType} /></td>;
		} else if ((typeof c.data.dropdown.typeItems !== "undefined") &&
			(typeof item.actionFunc !== "undefined")) {
			return <td width="25%" key={"button" + i}><span>
				<DropDown
					items={c.data.dropdown.typeItems}
					fields={{ id: "id", name: "field" }}
					multiSelect={false}
					sort={false}
					wantSelectAll={false}
					onChange={item.actionFunc.bind(null, item)}
				/>
			</span></td>;
		}
		return <td width="15%" key={"button" + i}><span>meep</span></td>;
	},
	createRowExpandWithItem: function (c, i, item) {
		if (this.props.organisationV5) {
			return (
				<td id={c.key} key={i} title={c.title ? c.title : ""} onClick={this._rowExpandEvent} className="organisation-row">
					<span style={{ marginLeft: this.props.marginLeft }}>
						<span data-qa-id={'QA_toggle_button_' + item[c.key]} style={{ cursor: 'pointer', marginRight: '5px' }} aria-hidden="true" onClick={this._rowExpandEvent}>
							{this.props.childExpand ? <i className="icon-chevron-up"></i> : <i className="icon-chevron-down"></i>}
						</span>{item[c.key]}
					</span>
				</td>
			)
		}

		if (this.props.expandable) {
			return (
				<td className="table-link" id={c.key} key={i} title={c.title ? c.title : ""} onClick={this._rowExpandEvent}>{item[c.key]}
					{this.props.data.id ? <span title={c.title ? c.title : ""} style={{ float: 'right', width: '25px', paddingLeft: '7px', height: '20px' }}><b>{this.props.childExpand ? '-' : '+'}</b></span> : ""}
				</td>
			);
		} else {
			if (typeof item.clickFunc !== null) {
				return (
					<td id={c.key} key={i} onClick={item.clickFunc.bind(null, item)}>
						<span style={{ marginLeft: this.props.marginLeft }}>{item[c.key]}</span><span className="table-link" style={{ float: 'right', width: '25px', paddingLeft: '7px', height: '20px' }} onClick={this._rowExpandEvent}>{this.props.childExpand ? '-' : '+'}</span>
					</td>
				);
			}
			return (
				<td id={c.key} key={i} onClick={this.__rowClickEvent}>
					<span style={{ marginLeft: this.props.marginLeft }}>{item[c.key]}</span><span className="table-link" style={{ float: 'right', width: '25px', paddingLeft: '7px', height: '20px' }} onClick={this._rowExpandEvent}>{this.props.childExpand ? '-' : '+'}</span>
				</td>
			);
		}
	},
	render: function () {
		var enableSubHeaders = this.props.enableSubHeaders;
		var columns = this.props.columns;
		var data = this.props.data;
		var action = this.props.actions;
		var controller = this.props.controllerName;
		var style = { cursor: 'pointer', maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' };
		var htmlSupport = this.props.htmlData;
		var td = function (item) {
			return columns.map(function (c, i) {
				var tableButton = <span onClick={this.handleButtonClick}></span>;
				if (c.type == 'button') {
					var requetUri = c.data.action;
					if (c.data.requestParams) {
						var params = decodeURIComponent($.param(c.data.requestParams));
						requetUri = c.data.action + "?" + params;
					}
					var action = this.actionUrl(requetUri, item);
					return (
						<td width="16%" key={"button" + i}>
							<span data-qa-id={'QA_button_name_' + item[c.key]} onClick={this.handleButtonClick.bind(null, action)}>
								<i className={c.data.className} /> {c.data.name}
							</span>
						</td>
					);
				}
				else if (c.type == 'customButton') {
					if (c.key && !item[c.key]) {
						var data = c.data[0];
						return <td width="20%" key={"button" + i}><TableButton key={"activateBtn_" + i} onButtonClick={this.handleButtonClick} action={this.actionUrl(data.action, item)} src={data.src} buttonName={data.name} color={data.color} actionType={data.actionType} /></td>;
					}
					else {
						var data = c.data[1];
						return <td width="20%" key={"button" + i}><TableButton key={"deactivateBtn_" + i} onButtonClick={this.handleButtonClick} action={this.actionUrl(data.action, item)} src={data.src} buttonName={data.name} color={data.color} actionType={data.actionType} /></td>;
					}
				}
				else if (c.type == 'checkbox') {
					var defaultChecked = this.props.selected;
					return <td key={"button" + i} ref="selector" id={item[c.key]}><input type={"checkbox"} name="id[]" id={item[c.key]} value={item[c.key]} defaultChecked={defaultChecked} onClick={this.checkboxHandleChange} />
						<i /></td>;
				} else if (c.type == 'controlled-checkbox') {
					let icon = <i />;
					if (typeof this.props.acctId !== "undefined") {
						if (itemRowPfx + this.props.acctId == this.props.id) {
							icon = <i style={{color:"#f4213f"}} className="fa fa-exclamation-triangle"></i>
						}
					}
					return (
						<td key={"ccb" + i} ref="selector">
							<TableRowCtrlCheckbox row={this.props.id}
								col={i} checked={item[c.key]}
								onCtrlCheckboxChange={this.props.onCtrlCheckboxChange} />
							{icon}
						</td>
					);
				} else if (c.type == 'function') {
					return (
						<td key={"function" + i}>
							{c.data(item.id, i, c.key)}
						</td>
					);
				} else if (c.type == 'image') {
					return <td width="10%" key={"image" + i} onClick={this._rowClickEvent}><div dangerouslySetInnerHTML={{ __html: item[c.key] }} /></td>;
				}
				else if (c.type == 'time') {
					return <td width="10%" key={"time" + i} onClick={this._rowClickEvent}><div dangerouslySetInnerHTML={{ __html: item[c.key] }} /></td>;
				}
				else if (c.type == 'top') {
					return <td width="10%" key={"top" + i} onClick={this._rowClickEvent}><div dangerouslySetInnerHTML={{ __html: item[c.key] }} /></td>;
				}
				else if (c.type == 'withColor') {
					return <td className={c.key} key={"withColor" + i}>{item[c.key]} <span style={{ width: "15px", height: "15px", display: "inline-block", verticalAlign: "bottom", background: item[c.key] }}></span></td>;
				}
				else if (c.type == 'thumbsUp') {
					return <td width="10%" style={{ textAlign: 'center' }} key={"thumbsUp" + i}><span ><i className={'icon icon-thumbs-up'} /> {item[c.key].toString()}</span></td>;
				}
				else if (c.type == 'thumbsDown') {
					return <td width="10%" style={{ textAlign: 'center' }} key={"thumbsDown" + i}><span ><i className={'icon icon-thumbs-down'} /> {item[c.key].toString()}</span></td>;
				}
				else if (c.type == 'actionLink') {
					return <td width="16%" key={"actionLink" + i}><span onClick={this.onActionRow.bind(null, c.data)}><i className={c.data.className} /> {c.data.name}</span></td>;
				}
				else if (c.type == 'removeLink') {
					return <td width="10%" key={"removeLink" + i} onClick={this.onDeleteRow} data-qa-id={"QA_removeLink_" + item[c.id]}><div dangerouslySetInnerHTML={{ __html: item[c.key] }} /></td>;
				}
				else if (c.type == 'actionCombineLink') {
					if (this.props.tagV5) {
						var requetUri = c.data.action;
						if (c.data.requestParams) {
							var params = decodeURIComponent($.param(c.data.requestParams));
							requetUri = c.data.action + "?" + params;
						}
						var action = this.actionUrl(requetUri, item);

						return <td width="20%" key={"actionCombineLink" + i} >
							<ActionsWrapper>
								<span>
									<CustomAdd onClick={this.onAddActionRow} />
									<Edit onClick={this.onEditActionRow} />
									<Delete onClick={this.handleButtonClick.bind(null, action)} />
								</span>
							</ActionsWrapper>
						</td>
					}

					if (this.props.organisationV5) {
						let situationalIconDelete = null;
						let situationalIconAdd = <CustomAdd onClick={this.onAddActionRow} />
						let situationalIconEdit = <Edit onClick={this.onEditActionRow} />
						if (c.orgType && c.orgType == 'edit') {
							var requetUri = c.data.action;
							if (c.data.requestParams) {
								var params = decodeURIComponent($.param(c.data.requestParams));
								requetUri = c.data.action + "?" + params;
							}
							var action = this.actionUrl(requetUri, item);
							situationalIconDelete = <Delete onClick={this.handleButtonClick.bind(null, action)} />
							situationalIconAdd = <span className="situationalAdd"></span>
							situationalIconEdit = <span className="situationalEdit"></span>
						}
						if (c.orgType && c.orgType == 'area-smpp') {
							var requetUri = c.data.action;
							var enabled = item.enabled == "True" ? true : false;
							if (c.data.requestParams) {
								var params = decodeURIComponent($.param(c.data.requestParams));
								requetUri = c.data.action + "?" + params;
							}
							var action = this.actionUrl(requetUri, item);
							situationalIconDelete = <Delete onClick={this.handleButtonClick.bind(null, action)} />
							situationalIconAdd = <SwitchCheckbox
								active={enabled ? true : false}
								buttonClassName={""}
								id={"orgActiveSwitch"}
								name={"orgActive"}
								type="checkbox"
								onClick={()=>{this.props.onHandleEnableChange(!enabled)}}
							/>
						}
						if (item.subType) {
							situationalIconDelete =
								<SwitchCheckbox
									active={item.Active}
									buttonClassName={""}
									id={"orgActiveSwitch"}
									name={"orgActive"}
									type="checkbox"
									onClick={item.subButton.action.bind(null, item)}
								/>
							if (item.subType == 'dropdown') {
								situationalIconDelete = <Delete onClick={item.subButton.action.bind(null, item)} />
								situationalIconAdd = <span className="situationalAdd"></span>
							}
						}
						return <td width="25%" key={"actionCombineLink" + i} >
							<ActionsWrapper>
								<span>
									{situationalIconEdit}
									{situationalIconDelete}
									{situationalIconAdd}
								</span>
							</ActionsWrapper>
						</td>
					}

					return <td width="20%" key={"actionCombineLink" + i} >
						<ActionsWrapper>
							<span>
								<Edit onClick={this.onEditActionRow} />
								<Delete onClick={this.onDeleteRow} />
							</span>
						</ActionsWrapper>
					</td>
				}
				else if (c.type == 'copyLink') {
					return <td width="10%" key={"copyLink" + i} onClick={this.onCopyRow} data-qa-id={"QA_copyLink_" + item[c.id]}><div dangerouslySetInnerHTML={{ __html: item[c.key] }} /></td>;
				}
				else if (c.type == 'editLink') {
					return <td key={"editLink" + i} onClick={this.onEditRow}><div dangerouslySetInnerHTML={{ __html: item[c.key] }} /></td>;
				}
				else if (c.type == 'customJQueryComponent') {
					return <td key={'customJQueryComponent' + i} id={'customJQueryComponent' + item[c.key]}></td>;
				} else if (c.type == 'showPreview') {
					var viewId = 0;
					if (typeof this.props.data.id !== "undefined") {
						viewId = this.props.data.id;
					} else if (typeof this.props.data.Id !== "undefined") {
						viewId = this.props.data.Id;
					}
					var display = false;
					var displayId = false;
					var spanStyle = {};
					var iconClass = '';
					if (c.key == 'name') {
						display = true;
						spanStyle = {
							paddingRight: '10px',
						}
						iconClass = 'icon-folder';
						if (this.props.data.hasOwnProperty('connectedAreas')) {
							iconClass = 'icon-knowledgebase';
						}
						if (this.props.data.hasOwnProperty('answer')) {
							iconClass = 'icon-report-executive';
						}
						if (this.props.knowledgeBase) {
							displayId = true;
						}
					}

					if (this.props.data[c.key] != 0) {
						return (
							<td
								style={style}
								id={c.key}
								key={i}
								onClick={this._rowClickEvent}
								draggable={this.props.draggable}
								onDragStart={this.handleDragStart}
								onMouseEnter={this._rowHoverEvent}
								onMouseLeave={this._rowHoverOutEvent}
								data-qa-id={"QA_name_" + viewId}
							>
								{display ? <span style={spanStyle}><i className="icon-grip" style={{ paddingRight: '5px' }}></i><i className={iconClass}></i></span> : ''}
								{htmlSupport ? <span dangerouslySetInnerHTML={{ __html: item[c.key] }} /> : item[c.key]}
								{displayId ? <span> (ID:{viewId})</span> : ""}
								<div className={"previewer view_" + viewId} style={{ display: this.state.display }}>
									<div className='arrow-up'></div>
									<div className='pre_header'>{this.props.onHover ? this.props.data[this.props.hoverFields.name] : ""}</div>
									<div className='pre_title'>
										<span dangerouslySetInnerHTML={{ __html: c.hoverTitle }} />
									</div>
									<div className='pre_body'>
										{this.props.onHover ? this.props.onHover(this.props.data[this.props.hoverFields.id], this.props.data[this.props.hoverFields.name], c.key) : ""}
									</div>
								</div>
							</td>
						)
					} else {
						return <td style={style} id={c.key} key={i} title={item[c.key]} data-qa-id={"QA_name_" + viewId}>{item[c.key]}</td>;
					}
				} else if (c.subHeaders) {
					return c.subHeaders.map(function (cs, index) {
						return <td style={style} id={"sub_" + cs.key} key={index} title={item[cs.key]} onClick={this._rowClickEvent}>{item[cs.key]}</td>
					}, this);
				} else if (c.type == 'clickable') {
					return <td className="table-link" id={c.key} key={i} onClick={this._rowClickEvent}>{item[c.key]}</td>;
				} else if (c.type == 'unclickable') {
					return <td style={style} id={c.key} key={i} title={item[c.key]}>{item[c.key]}</td>;
				} else if (c.type == 'ratings') {
					var stars = item[c.key];
					var rating = [];
					for (i = 0; i < stars; i++) {
						rating.push(<i className='fa fa-star'></i>);
					}
					return <td>{rating}</td>;
				} else if (c.type == 'tooltip') {
					var tooltipStyle = {
						minHeight: '75px',
						minWidth: '150px',
						border: '2px solid black',
						padding: '2px',
						backgroundColor: 'white',
						position: 'absolute',
						zIndex: '1000',
						display: this.state.tooltipHover ? 'block' : 'none'
					};
					var tooltipdata = item[c.key].map(function (line, i) {
						if (i == 0) {
							return (<span key={i}><b>{L(line)}</b><br /></span>);
						} else {
							return (<span key={i}>{line}<br /></span>);
						}
					});
					return (
						<td style={{ cursor: 'pointer' }} id={c.key} key={i} onClick={this._rowClickEvent}>
							<div onMouseOver={this.tooltipHandleMouseIn} onMouseOut={this.tooltipHandleMouseOut}>{item[c.key] != null ? item[c.key].length - 1 : 0}</div>
							<div style={tooltipStyle}>{tooltipdata}</div>
						</td>
					);
				} else if (c.type == 'rowExpand' && item.childList && item.childList.length > 0) {
					if (this.props.adminTagExpandable) {
						return (
							<td id={c.key} key={i} onClick={this._rowClickEvent}>
								{this.props.tagV5 ? <span style={{ marginLeft: this.props.marginLeft }}>
									<span data-qa-id={'QA_toggle_button_' + item[c.key]} style={{ cursor: 'pointer', marginRight: '5px' }} aria-hidden="true" onClick={this._rowExpandEvent}>
										{this.props.childExpand ? <i className="icon-chevron-up"></i> : <i className="icon-chevron-down"></i>}
									</span>{item[c.key]}
								</span> : <span style={{ marginLeft: this.props.marginLeft }}>{item[c.key]}
									<span className="table-link" style={{ float: 'right', width: '25px', paddingLeft: '7px', height: '20px' }} onClick={this._rowExpandEvent}>
										{this.props.childExpand ? '-' : '+'}
									</span>
								</span>}
							</td>
						);
					} else {
						if (this.props.expandable) {
							return (
								<td className="table-link" id={c.key} key={i} title={c.title ? c.title : ""} onClick={this._rowExpandEvent}>{item[c.key]}
									{this.props.data.id ? <span title={c.title ? c.title : ""} style={{ float: 'right', width: '25px', paddingLeft: '7px', height: '20px' }}><b>{this.props.childExpand ? '-' : '+'}</b></span> : ""}
								</td>
							);
						} else {
							return (
								<td id={c.key} key={i} onClick={this._rowClickEvent}>
									<span style={{ marginLeft: this.props.marginLeft }}>{item[c.key]}</span><span className="table-link" style={{ float: 'right', width: '25px', paddingLeft: '7px', height: '20px' }} onClick={this._rowExpandEvent}>{this.props.childExpand ? '-' : '+'}</span>
								</td>
							);
						}
					}
				} else if (c.type == 'rowExpandWithItem' && item.childList
					&& item.childList.length > 0) {
					return this.createRowExpandWithItem(c, i, item);
				} else if (c.type == 'withRowStyle' && item.style) {
					return <td style={item.style} id={c.key} key={i} title={item[c.key]} onClick={this._rowClickEvent} draggable={this.props.draggable} onDragStart={this.props.onDragStart}><span style={{ marginLeft: this.props.marginLeft }}>{item[c.key]}</span></td>;
				} else if (c.type == 'subButton') {
					var requestUri = "";
					if (item.subButton) {
						return <td width="15%" key={"button" + i}><span onClick={item.subButton.action.bind(null, item)}><i className={item.subButton.className} /> {item.subButton.name}</span></td>;
					}
				} else if (c.type == 'dropDownOrButton') {
					return this.createDropDownOrButton(c, i, item);
				} else if (c.type == 'attachment') {
					const attachments = [];
					each(item.attachments, (k, i) => {
						attachments.push(
							<span className="customer-note-attachment" key={i}>
								<i className='fa fa-paperclip file-icon' aria-hidden="true" />
								<a
									className='link'
									href={k.downloadURL}
									target='_blank'
									data-qa-id={"attachment-" + k.fileName}
									onClick={this._rowClickEvent}
								>
									{k.fileName}
								</a>
								{/* ({humanByteSize(k.estSize)}) */}
							</span>
						);
					});
					return <td key={"attachment" + i}>{attachments}</td>
				} else {
					let marginLeft = this.props.marginLeft;
					let className = "";
					if ((item.childList && item.childList.length == 0) && this.props.data.tagLevel) {
						if (this.props.data.tagLevel == 1) {
							marginLeft = '18px';
							if (!this.props.tagV5) {
								marginLeft = '0px';
							}
						} else {
							let margin = (this.props.data.tagLevel - 1) * 15 + 3;
							if (margin > this.props.rowMax) {
								margin = this.props.rowMax;
							}
							marginLeft = `${margin}px`
						}
					}
					if (c.key == "levelsUnderneath" || c.key == "tagDeleteString") {
						marginLeft = '0px';
					}

					if (this.props.organisationV5) {
						marginLeft = '20px';
						className = "organisation-row"
						if (item.subType) {
							if (item.subType == 'dropdown') {
								className = "area-row"
							}
						}
						if (this.props.areaEditV5) {
							marginLeft = '3px';
						}
					}
					return (
						<td
							style={style}
							id={c.key}
							key={i}
							title={item[c.key]}
							onClick={this._rowClickEvent}
							draggable={this.props.draggable}
							onDragStart={this.props.onDragStart}
							data-qa-id={'QA_td_name_' + c.key}
							className={className}
						>
							<span data-qa-id={'QA_td_value_' + c.key} style={{ marginLeft: marginLeft }}>
								{item[c.key]}
							</span>
						</td>
					);
				}
			}.bind(this));
		}.bind(this);
		return (
			<tr key={data} id={this.props.id ? this.props.id : ""}>{td(data)}</tr>
		)
	}
});

const dataLength = data => data && data.length ? data.length : 0;

const defaultPageSize = 5;

const validatePageSize = pageSize => pageSize < 1 ? defaultPageSize : pageSize;

const calculateCurrentPage = (currentPage, dataSize, pageSize) => {
	if (dataSize <= 1 || currentPage < 1) {
		return 1;
	} else if (currentPage * pageSize > dataSize) {
		return 1;
	}
	return currentPage;
};

// NOTE: this anti-pattern just to maintain backward compatibility.
// Page size, current page has NO magic to handle their internal state. A proper
// code need to be written to give it correct value. If page size can be changed
// outside of the component then it MUST - MUST else it is NOT React - be a
// props AND currentPage MUST also at the same level of pageSize props. This
// component is not doing that, so anti-pattern. Another word, useEffect within
// this component is a bullshit code, do not follow it.
const withPaginationControl = Component => ({
	data
	, pageSize: pagingSize = defaultPageSize
	, updatePageSize: onPageSizeChange
	, ...props
}) => {
	const [currentPage, setCurrentPage] = useState(1)
		, [pageSize, setPageSize] = useState(validatePageSize(pagingSize))
		, updatePageSize = useCallback(size => {
			if (typeof onPageSizeChange === "function") {
				setPageSize(Number(size));
				onPageSizeChange(size);
			}
		}, [onPageSizeChange])
		, handlePageChange = useCallback(page => {
			setCurrentPage(page);
		}, [])
		, length = dataLength(data)
		;
	useEffect(() => {
		setPageSize(validatePageSize(pagingSize));
	}, [pagingSize]);
	useEffect(() => {
		setCurrentPage(page => calculateCurrentPage(page, length, pageSize));
	}, [length, pageSize]);
	return (
		<Component
			currentPage={currentPage}
			data={data}
			onPageChange={handlePageChange}
			pageSize={pageSize}
			updatePageSize={updatePageSize}
			{...props}
		/>
	);
};

const defaultPaginationStyle = {
	fontFamily: "metabold"
	, background: "#FFF"
	, fontSize: "12px"
};

const maxSubRowMarginLeft = 48;

const getMarginLeftByLevel = (level, max) => {
	let margin = 0
	if (level > 0) {
		margin = (level - 1) * 10 + (level - 1) * 5;
		if (margin > max) {
			margin = max
		}
	}
	return `${margin}px`
}

// Note: To enable pagination, add props:
//  paginate=true
//  pageSize=n
//  enablePageSizeChange={bool}
//  paginationStyle={style} if any, when calling the component
var ControlledTableComponent = createReactClass({
	getInitialState: function () {
		return {
			data: [],
			sortDir: "",
			sortColumn: "",
			expandParentList: [],
			showPageLink: true
		};
	},
	getDefaultProps: function () {
		return {
			paginate: false,
			enablePageSizeChange: false,
			paginationStyle: defaultPaginationStyle,
			enableSubHeaders: false,
			draggable: false,
			maxRowExpandLevel: 3,
			maxRowExpandMarginLeft: maxSubRowMarginLeft
		}
	},
	componentDidMount: function () {
		this.tcId = setTimeout(function () {
			this.setState({ data: this.props.dataSource });
		}.bind(this), 10);
	},
	componentWillUnmount: function () {
		clearInterval(this.tcId);
	},
	componentWillReceiveProps: function (nextProps) {
		clearInterval(this.tcId);
		this.tcId = setTimeout(function () {
			this.setState({ data: nextProps.dataSource });
		}.bind(this), 1);
	},
	handlePageSizeChange: function (size) {
		const { updatePageSize } = this.props;
		if (typeof updatePageSize === "function") {
			updatePageSize(size);
		}
	},
	getUniqueList: function (list) {
		var usedObjects = {};
		for (var i = list.length - 1; i >= 0; i--) {
			var so = JSON.stringify(list[i]);
			if (usedObjects[so]) {
				list.splice(i, 1);
			} else {
				usedObjects[so] = true;
			}
		}
		return list;
	},
	sortByColumn: function (array, column, sortDir) {
		var dataCheck = "";
		var dataType = "string";
		if (array != undefined && array.length > 0) {
			dataCheck = array[0][column];
			if (dataCheck === parseInt(dataCheck, 10)) {
				dataType = "int";
			}
			return array.sort(function (a, b) {
				var x = a[column];
				var y = b[column];
				if (sortDir === 'asc') {
					if (dataType == "string") {
						return x.toString().toLowerCase().localeCompare(y.toString().toLowerCase(), undefined, {
							numeric: true
						});
					} else {
						return x - y;
					}
				} else {
					if (dataType == "string") {
						return y.toString().toLowerCase().localeCompare(x.toString().toLowerCase(), undefined, {
							numeric: true
						});
					} else {
						return y - x;
					}
				}
			});
		}
	},
	sort: function (column) {
		if (this.props.onSort) this.props.onSort();
		var sortDir = this.state.sortDir;
		var data = this.state.data;
		if (column != undefined && column && data != undefined && data.length > 0) {
			var sortedData = this.sortByColumn(data, column, sortDir);
			for (var i = 0; i < sortedData.length; i++) {
				var rowData = sortedData[i];
				if (typeof rowData.childList !== "undefined") {
					if (rowData.childList.length > 0) {
						var childData = rowData.childList;
						var sortedChild = this.sortByColumn(childData, column, sortDir);
						rowData.childList = sortedChild;
					}
				}
			}
			this.setState({ data: sortedData });
		}
		sortDir = (sortDir === 'asc' ? 'dsc' : 'asc');
		this.setState({ sortDir: sortDir });
	},
	handleRowSelected: function (itemId, isSelected) {
		this.props.onChangeSelection(this.getSelectedItemsList(itemId, isSelected));
	},
	getSelectedItemsList: function (itemid, isSelected) {
		if (isSelected) {
			this.isSelected(itemid) ? this.props.selectedItems : this.props.selectedItems.push(itemid);
		} else {
			if (this.isSelected(itemid)) {
				this.props.selectedItems.splice(this.findSelectedIndex(itemid), 1);
			}
		}
		return this.props.selectedItems;
	},
	findSelectedIndex: function (id) {
		var found = -1;
		if (this.props.selectedItems && this.props.selectedItems.length > 0) {
			this.props.selectedItems.map(function (itemid, i) {
				if (itemid == id) {
					found = i;
				}
			}, this);
		}
		return found;
	},
	isSelected: function (id) {
		return this.findSelectedIndex(id) < 0 ? false : true;
	},
	getClickedRowId: function (itemId, read) {
		const { highlightRow } = this.props;
		if (typeof highlightRow === "function") {
			highlightRow(itemId, read);
		}
	},
	handleDeleteAction: function (action) {
		this.props.itemDeleteAction(action);
	},
	handleChildExpand: function (id, action) {
		var pl = this.state.expandParentList;
		if (action == true) {
			var index = pl.indexOf(id);
			if (index > -1) {
				pl.splice(index, 1);
			}
		} else {
			pl.push(id);
		}
		this.setState({ expandParentList: pl });
	},
	renderPageLinks: function (page, pageInfo) {
		var active = "";
		var paginationLink = [];
		var pageLinksPrev = [];
		var pageLinksNext = [];
		var pageArrowStyle = { fontSize: "14px" };
		let columnClass = "col-md-6";
		if (this.props.enablePageSizeChange || !this.state.showPageLink || page.numPages < 5) {
			columnClass = "col-md-7";
		}
		if (this.props.tagV5 || this.props.organisationV5) {
			columnClass = "col-md-12 pagination-row";
		}
		if (this.props.pageV5) {
			columnClass = "col-md-11";
		}

		if (page.numPages == 1) {
			this.state.showPageLink = false;
		}
		if (page.currentPage > 1) {
			if (page.currentPage > 2) {
				pageLinksPrev.push(<span key="firstPageLink" className="fa fa-angle-double-left page-arrow" style={pageArrowStyle} onClick={page.handleClick(1)} title={I("Go to first")}></span>)
				pageLinksPrev.push(' ')
			}
			pageLinksPrev.push(<span key="prevPageLink" className="fa fa-angle-left page-arrow" style={pageArrowStyle} onClick={page.handleClick(page.currentPage - 1)} title={I("Previous")}></span>)
			pageLinksPrev.push(' ')
		}

		if (page.currentPage < page.numPages) {
			pageLinksNext.push(' ')
			pageLinksNext.push(<span key="nextPageLink" className="fa fa-angle-right page-arrow" style={pageArrowStyle} onClick={page.handleClick(page.currentPage + 1)} title={I("Next")}></span>)
			if (page.currentPage < page.numPages - 1) {
				pageLinksNext.push(' ')
				pageLinksNext.push(<span key="lastPageLink" className="fa fa-angle-double-right page-arrow" style={pageArrowStyle} onClick={page.handleClick(page.numPages)} title={I("Go to last")}></span>)
			}
		}
		if (page.numPages > 10) {
			for (var i = 1; i <= 10; i++) {
				if (i == page.currentPage) {
					active = "active";
				} else {
					active = "";
				}
				paginationLink.push(<span key={"pagelink_" + i} className={"paging " + active} id={"paging_" + i} onClick={page.handleClick(i)}> {i} </span>);
			}
			paginationLink.push("...");
			paginationLink.push(<span key={"pagelink_" + i} className={"paging " + active} id={"paging_" + page.numPages} onClick={page.handleClick(page.numPages)}> {page.numPages} </span>);
		} else {
			if (page.numPages != 1) {
				for (let i = 1; i <= page.numPages; i++) {
					if (i == page.currentPage) {
						active = "active";
					} else {
						active = "";
					}
					paginationLink.push(<span key={"pagelink_" + i} className={"paging " + active} id={"paging_" + i} onClick={page.handleClick(i)}> {i} </span>);
				}
			}
		}
		return (
			<div className={columnClass}>
				{pageInfo}
				<div className="display-paging">
					{pageLinksPrev} {paginationLink} {pageLinksNext}
				</div>
			</div>
		)
	},
	pager: function (page) {
		const currentPage = page.currentPage - 1;
		const pageInfo = [];
		const total = this.state.data ? this.state.data.length : 0;
		let startVal = "";
		let endVal = "";
		let columnClass = "";
		let hideDiv = this.props.enablePageSizeChange;
		if (!this.props.enablePageSizeChange) {
			columnClass = "col-md-4";
		}
		if (this.props.tagV5 || this.props.pageV5 || this.props.organisationV5) {
			hideDiv = true;
			columnClass = "";
		}

		startVal = ((currentPage) * this.props.pageSize) + 1;
		endVal = ((currentPage + 1) * this.props.pageSize);
		if (endVal > total) {
			endVal = total;
		}
		let pageStatusText = I("Showing");
		if(this.props.incompletePagination){
			pageStatusText = I("Currently showing");
		}
		let paginationTexts = `${pageStatusText} ${startVal} - ${endVal} of ${total}`;
		if (this.state.data && this.state.data.length > 0) {
			pageInfo.push(<span key="centerPagination" className="currentPage">{paginationTexts}</span>)
		} else {
			pageInfo.push(<span key="centerPagination" className="currentPage">{I("No Data Available.")}</span>)
		}
		const small = this.props.enablePageSizeChange || !(page.numPages < 5);
		return (
			<div className="paginationRow" style={this.props.paginationStyle}>
				<div className={columnClass} hidden={hideDiv}>
				</div>
				{/* <div className={classNames({ "col-md-4": small, "col-md-6": !small })}>
					<div className={classNames(
						"pager display-shows"
						, { "align-center": small, "align-right": !small }
					)}>
						{pageInfo}
					</div>
				</div> */}
				{this.renderPageLinks(page, pageInfo)}
				{this.renderPaginationConfig()}
			</div>
		)
	},
	getPage: function () {
		const start = this.props.pageSize * (this.props.currentPage - 1);
		const end = start + this.props.pageSize;
		return {
			currentPage: this.props.currentPage,
			data: (this.state.data != null ? this.state.data.slice(start, end) : []),
			numPages: this.getNumPages(),
			handleClick: pageNum => e => this.props.onPageChange(pageNum)
		};
	},
	getNumPages: function () {
		var numPages = "";
		if (this.state.data && this.state.data.length > 0) {
			numPages = Math.floor(this.state.data.length / this.props.pageSize);
			if (this.state.data.length % this.props.pageSize > 0) {
				numPages++
			}
		}
		return numPages;
	},
	renderSizeList: function (i) {
		return (
			<span
				className={classNames("pagesize", { active: this.props.pageSize == i })}
				id={"pagesize-" + i}
				onClick={this.handlePageSizeChange.bind(this, i)}
			>
				{i}
			</span>
		);
	},
	//can be disable as props enablePageSizeChange = false
	renderPaginationConfig: function () {
		if (this.props.enablePageSizeChange) {
			return (
				<div className="col-md-4">
					<div className="align-right display-option">
						{I("Show")} :
						{this.renderSizeList(10)} |
						{this.renderSizeList(15)} |
						{this.renderSizeList(17)} |
						{this.renderSizeList(25)} |
						{this.renderSizeList(35)} |
						{this.renderSizeList(50)}
					</div>
				</div>
			)
		}
	},
	renderTableHeader: function () {
		return (
			<TableHeader columns={this.props.columns} onSort={this.sort} sortDir={this.state.sortDir} sortColumn={this.state.sortColumn} enableSubHeaders={this.props.enableSubHeaders} buttonAction={this.props.itemAddAction} organisationV5={this.props.organisationV5} />
		)
	},
	renderTableFooter: function () {
		if (this.props.footerData) {
			return (
				<TableFooter columns={this.props.footerColumns} id={this.props.id} data={this.props.footerData} />
			)
		}
	},
	renderTableRowsBase: function (row, data, level, limit) {
		if ((typeof limit !== 'number' || limit < 0 || level <= limit) &&
			data &&
			data.length > 0) {
			each(data, (item, index) => {
				const { id, childList } = item
				row.push(this.generateTableRow(
					`level_${level}_` + id + "_" + index,
					item,
					getMarginLeftByLevel(level, this.props.maxRowExpandMarginLeft)
				))
				if (this.state.expandParentList.indexOf(id) > -1) {
					this.renderTableRowsBase(row, childList, level + 1, limit)
				}
			})
		}
		return row
	},
	renderTableRows: function (data) {
		return this.renderTableRowsBase([], data, 1, this.props.maxRowExpandLevel)
	},
	generateTableRow: function (idx, item, marginLeft) {
		return (
			<TableRow
				key={idx}
				id={item.Id ? itemRowPfx + item.Id : itemRowPfx + item.id}
				enableSubHeaders={this.props.enableSubHeaders}
				controllerName={this.props.controllerName}
				data={item}
				columns={this.props.columns}
				actions={this.props.actions}
				onDeleteRow={this.props.onDeleteRow}
				onCopyRow={this.props.onCopyRow}
				onEditRow={this.props.onEditRow}
				onEditActionRow={this.props.onEditActionRow}
				onAddActionRow={this.props.onAddActionRow}
				onActionRow={this.props.onActionRow}
				onSelect={this.handleRowSelected}
				selected={this.isSelected(item.id)}
				rowHighlight={this.getClickedRowId}
				onDeleteAction={this.handleDeleteAction}
				onHover={this.props.onHoverColumn ? this.props.onHoverColumn : ""}
				hoverOut={this.props.hoverOutColumn ? this.props.hoverOutColumn : ""}
				hoverFields={this.props.hoverFields}
				previewTitle={this.props.previewTitle}
				draggable={this.props.draggable}
				onDragStart={this.props.onDragStart}
				htmlData={this.props.htmlData}
				childExpand={this.state.expandParentList.indexOf(item.id) > -1 ? true : false}
				onChildExpand={this.handleChildExpand}
				marginLeft={marginLeft}
				rowMax={maxSubRowMarginLeft}
				expandable={this.props.expandable}
				onCtrlCheckboxChange={this.props.onCtrlCheckboxChange}
				altOnClickHandler={this.props.altOnClickHandler}
				knowledgeBase={this.props.knowledgeBase}
				adminTagExpandable={this.props.adminTagExpandable}
				tagV5={this.props.tagV5}
				pageV5={this.props.pageV5}
				organisationV5={this.props.organisationV5}
				areaEditV5={this.props.areaEditV5}
				itemAddAction={this.props.itemAddAction}
				onHandleEnableChange={this.props.onHandleEnableChange}
				acctId={this.props.acctId}
			/>
		);
	},
	renderEmptyTableBody: function () {
		return (
			<div className="col-lg-12" style={{ textAlign: 'center' }}>
				<span style={{ fontSize: '17px', color: '#e5e5e5' }}>{I("No data")}</span>
			</div>
		);
	},
	render: function () {
		var tableRows = "";
		var pager = "";
		if (!this.props.paginate) {
			tableRows = this.renderTableRows(this.state.data);
		} else {
			var page = this.getPage();
			tableRows = this.renderTableRows(page.data);
			pager = this.pager(page);
		}
		if (this.state.data && this.state.data.length > 0) {
			return (
				<div className="table-wrappers">
					<table id={this.props.id} width={this.props.width} cellSpacing="1" cellPadding="0" data-border="0" className="table table-striped table-bordered table-highlight" data-height="200" data-toggle="table">
						{this.renderTableHeader()}
						<tbody>
							{tableRows}
						</tbody>
						{this.renderTableFooter()}
					</table>
					{this.state.data.length > 0 ? pager : ""}
				</div>
			)
		} else {
			return (
				<div className="table-wrappers">
					<table id={this.props.id} width={this.props.width} cellSpacing="1" cellPadding="0" data-border="0" className="table table-striped table-bordered table-highlight" data-height="200" data-toggle="table">
						{this.renderTableHeader()}
					</table>
					{this.renderEmptyTableBody()}
				</div>
			)
		}
	}
});

const TableComponent = memo(withPaginationControl(ControlledTableComponent));

var TableHeader = createReactClass({
	renderSubHeaders: function (sortDir, sortColumn, sortArrow) {
		var arrow = "\u00a0";
		var attach = null;
		if (this.props.columns.headerType == "image")
			attach = <span className={c.imageClass}></span>;
		if (this.props.organisationV5) {
			return this.props.columns.map(function (c, index) {
				if (c.subHeaderAction) {
					let sh = c.subHeaderAction;
					let button = <CustomAdd onClick={this.props.buttonAction} />
					if (sh.type && sh.type == "orgEdit") {
						return <th className={"external-subheader-button"} key={index} style={{ textAlign: 'center' }}>{button}</th>;
					}
					return <th className={"external-subheader"} key={index} style={{ textAlign: 'left' }} >{sh}</th>;
				}
			}.bind(this));
		}
		return this.props.columns.map(function (c, index) {
			if (c.subHeaders && c.subHeaders.length > 0) {
				return c.subHeaders.map(function (sh, i) {
					if (sh.key == sortColumn) {
						arrow = sortArrow[sortDir];
					} else {
						arrow = "";
					}
					return <th onClick={this.sort(sh)} className={"headerSortUp"} style={{ textAlign: 'left' }} key={i}>{sh.header} {arrow}{attach}</th>;
				}.bind(this));
			}
		}.bind(this));

	},
	renderSubHeaderCell: function () {
		var mainColumns = this.props.columns;
		var sortDir = this.props.sortDir;
		var sortColumn = this.props.sortColumn;
		var sortArrow = {
			asc: "▼",
			dsc: "▲"
		}
		return (
			<tr key="subHeaderRow">
				{this.renderSubHeaders(sortDir, sortColumn, sortArrow)}
			</tr>
		);
	},
	render: function () {
		var sortDir = this.props.sortDir;
		var sortColumn = this.props.sortColumn;
		var sortArrow = {
			asc: "▼",
			dsc: "▲"
		}
		var columns = this.props.columns;
		var colSpan = 0;
		var cell = columns.map(function (c, i) {
			if (this.props.enableSubHeaders) {
				if (c.subHeaders && c.subHeaders.length > 0) {
					colSpan = c.subHeaders.length;
				}
			}
			var arrow = "";
			if (c.key == sortColumn) {
				arrow = sortArrow[sortDir];
			}
			if (c.sortBy == sortColumn) {
				arrow = sortArrow[sortDir];
			}
			var attach = null;
			if (c.headerType == "image")
				attach = <span className={c.imageClass}></span>;
			return <th rowSpan={c.role == "mainHeader" ? 2 : 1} colSpan={colSpan} onClick={this.sort(c)} className={c.notClickable ? "headerSortUp notClickable" : "headerSortUp " + c.header} style={c.centerAlign ? { textAlign: 'center' } : (c.rightAlign ? { textAlign: 'right' } : { textAlign: 'left' })} key={i}><div dangerouslySetInnerHTML={{ __html: c.header }} />  {arrow}{attach}</th>;
		}.bind(this));

		if (this.props.enableSubHeaders) {
			return (
				<thead>
					<tr key="headerRow">{cell}</tr>
					{this.renderSubHeaderCell()}
				</thead>
			);
		} else {
			return (
				<thead><tr key="headerRow">{cell}</tr></thead>
			);
		}
	},
	sort: function (column) {
		return function (event) {
			var sortDir = this.props.sortDir;
			if (column.sortBy) {
				this.props.onSort(column.sortBy, sortDir);
			} else if (column.notClickable) {
				return;
			} else {
				this.props.onSort(column.key, sortDir);
			}
		}.bind(this)
	}
});

var TableFooter = createReactClass({
	getDefaultProps: function () {
		return {
			data: [],
			columns: []
		}
	},
	render: function () {
		var item = this.props.data;
		var columns = [];
		if (this.props.columns) {
			columns = this.props.columns;
		}
		var controller = "";
		return (
			<tfoot id={this.props.id} className="table-footer"><TableRow key="" id={item.Id ? itemRowPfx + item.Id : itemRowPfx + item.id} controllerName={controller} data={item} columns={columns} draggable={false} /></tfoot>
		)
	}
});
export default TableComponent;
