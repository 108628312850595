import React from 'react';
class Feature extends React.Component{
  render(){
    var condition = this.props.equals ? this.props.equals : "true";
    if('' + F(this.props.tag) == condition){
      return this.props.children;
    }
    return React.createElement('noscript', {});
  }
};
export default Feature;
