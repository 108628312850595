// OauthToken work together with Golang package 'c3/oauth' to provide 3-legged
// oauth2 (or similar) authorization flow. Both of them link through global
// variable c3oauth pass from back-end and 'provider' 'service' pair that
// register at back-end.
import React, { useCallback, useEffect, useRef } from 'react';
import shortid from 'shortid';
import { useDetectKeyPressed } from '../hooks/key';
import { windowPopupCenter } from '../../app/common/v5/helpers';

function callbackEvent(provider, service) {
	return "oauth." + provider.toLowerCase() + "." + service.toLowerCase();
}

function base64Encode(s) {
	return btoa(s).replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, ".");
}

const webdIfPressed = (isPressed, value = 'true') => {
	if (isPressed) {
		return `&webd=${value}`
	}
	return ''
}

const enableWebdConvertCodeToToken = ['shiftKey', { key: 'W' }]

export const useTokenAuthorization = (
	constants
	, provider
	, service
	, nonceCallback
) => {
	const isPressed = useDetectKeyPressed(enableWebdConvertCodeToToken)
	return useCallback(
		() => {
			const nonce = shortid.generate()
			// TODO: support cloud.cention.com/s/<space>? Likely no need because
			// the moment user clicking the Twilio connect button, he / she must
			// have already login but popup page may lost the cookies
			// information but user should be able to login again and still
			// redirect to the correct token page.
			const {
					authorize_urls,
					nonceReplacer,
					redirect_path,
					state_replacer
				} = constants
				, redirect_uri = 'https://'
					+ window.location.hostname
					+ redirect_path
					+ "?event=" + callbackEvent(provider, service)
					+ webdIfPressed(isPressed)
					+ "&provider=" + encodeURIComponent(provider)
					+ "&service=" + encodeURIComponent(service)
					+ "&state=" + encodeURIComponent(nonce)
				, url = authorize_urls[provider][service].replace(
						state_replacer
						// , encodeURIComponent(redirect_uri)
						, base64Encode(redirect_uri)
					).replace(nonceReplacer, nonce)
				;
			if (typeof nonceCallback === 'function') {
				nonceCallback(nonce)
			}
			if (process.env.NODE_ENV !== 'production') {
				if (isPressed) {
					console.log("dbg: token will be resolved from webd");
				}
			}
			windowPopupCenter(url,990,800)
		}
		, [constants, isPressed, nonceCallback, provider, service]
	);
}

const displayNone = {display: "none"};

const OauthToken = ({ provider, onTrigger, service }) => {
	useEffect(
		() => {
			const event = callbackEvent(provider, service);
			if (process.env.NODE_ENV !== 'production') {
				console.log("listen on token callback", provider, service);
			}
			$("body").on(event, (e, data) => {
				if (process.env.NODE_ENV !== 'production') {
					console.log("dbg: oauth callback data:", data);
				}
				if (typeof onTrigger === "function") {
					onTrigger(data);
				}
			});
			return () => {
				if (process.env.NODE_ENV !== 'production') {
					console.log("remove listener on token callback");
				}
				$("body").off(event);
			};
		}
		, [provider, onTrigger, service]
	);
	return <span style={displayNone} />;
};

export default OauthToken;
