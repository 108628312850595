import { useEffect, useMemo, useState } from "react";
import update from "immutability-helper";
import { I } from "../common/v5/config";
import { emptyObject } from "../common/constants";

const initKeysState = {keys: emptyObject};

// Input 'keys' is array. Its element can be string 'altKey', 'ctrlKey',
// 'metaKey', 'shiftKey' or object of {key: "key_name"}. Note: Pass input 'keys'
// as constant value else isPressed will always be recalculated. Eg:
// const keys = ["shiftKey", {key: "A"}]
//     , pressed = useDetectKeyPressed(keys)
//     ;
export const useDetectKeyPressed = keys => {
	const [ pressedKeys, setPressedKeys ] = useState(initKeysState)
		, isPressed = useMemo(
			() => {
				for (let i=0; i<keys.length; i++) {
					const key = keys[i]
						, typ = typeof key
						;
					if (typ === "string") {
						if (!pressedKeys[key]) {
							return false;
						}
					} else if (typ === "object") {
						if (!pressedKeys.keys[key.key]) {
							return false;
						}
					} else {
						if (process.env.NODE_ENV !== 'production') {
							console.log("invalid input keys:", key);
						}
						return false;
					}
				}
				return true;
			}
			, [keys, pressedKeys]
		)
		;
	useEffect(
		() => {
			const blurEvent = "blur.hookkeys"
				, keydownEvent = "keydown.hookkeys"
				, keyupEvent = "keyup.hookkeys"
				;
			if (process.env.NODE_ENV !== 'production') {
				console.log("listen keys:", keydownEvent, keyupEvent);
			}
			$("body").on(
				keydownEvent
				, ({ altKey, ctrlKey, key, metaKey, shiftKey }) => {
					setPressedKeys(state => update(
						state
						, {
							altKey: {$set: altKey}
							, ctrlKey: {$set: ctrlKey}
							, keys: {[key]: {$set: true}}
							, metaKey: {$set: metaKey}
							, shiftKey: {$set: shiftKey}
						}
					));
				}
			);
			$("body").on(
				keyupEvent
				, ({ altKey, ctrlKey, key, metaKey, shiftKey }) => {
					setPressedKeys(state => update(
						state
						, {
							altKey: {$set: altKey}
							, ctrlKey: {$set: ctrlKey}
							, keys: {$unset: [key]}
							, metaKey: {$set: metaKey}
							, shiftKey: {$set: shiftKey}
						}
					));
				}
			);
			$(window).on(blurEvent, () => {
				// When window loss focus, there is no way to accurately keep
				// track which button pressed or released. Reset the state and
				// user need to release any pressed key to start over pressing
				// key again for accurate tracking.
				if (process.env.NODE_ENV !== 'production') {
					console.log("window blurred!");
				}
				setPressedKeys(initKeysState);
			});
			return () => {
				if (process.env.NODE_ENV !== 'production') {
					console.log("remove key listeners");
				}
				$("body").off(keydownEvent);
				$("body").off(keyupEvent);
				$(window).off(blurEvent);
			};
		}
		, []
	);
	return isPressed;
};
