import React from 'react';
import createReactClass from 'create-react-class';

var CentionTextfield = createReactClass({
	getInitialState: function(){
		return{
			value: ""
		}
	},
	getDefaultProps: function () {
		return {
			className: 'form-control input-sm',
		}
	},
	onTextChange: function(event){
		event.preventDefault();
		this.props.onChange( event.target.value );
	},
	componentDidMount: function(){
	},
	handleBlur: function(e){
		if(this.props.onBlur)
			this.props.onBlur(e.target.value);
	},
	render: function(){
		var opt = {};
		if (this.props.disabled) {
			opt["disabled"] = "disabled";
		}
		return(
			<div>
				<input
					type={this.props.type != undefined ? this.props.type : 'text'}
					id={this.props.id}
					name={this.props.id}
					ref={this.props.id}
					placeholder={this.props.placeholder}
					defaultValue={this.props.setDefaultText}
					className={this.props.className}
					onChange={this.onTextChange}
					onBlur={this.handleBlur}
					value={this.props.value}
					autoComplete="off"
					data-qa-id={this.props["data-qa-id"]}
					{...opt}
				/>
			</div>
		);
	}
});
export default CentionTextfield;
