import React from 'react';
import update from 'react-addons-update';
import createReactClass from 'create-react-class';

var LibraryTreeQuestionPreview = createReactClass({
	getDefaultProps: function() {
		return {
			show: false,
			position: 'bottom',
			maxHeight: 0,
		};
	},
	render: function() {
		var style = {
			position: 'absolute',
			background: '#fff',
			overflowY: 'auto',
			zIndex: 1002,
			borderBottom: '1px solid #efefef',
			width: '550px',
			left: '25px'
		};
		if (!this.props.show) {
			style.display = 'none';
		}
		if (this.props.position == 'left') {
			style.top = '-75px';
			style.left = '-475px';
		}
		if (this.props.maxHeight > 0) {
			style.maxHeight = this.props.maxHeight + 'px';
		}
		var upArrowStyle = {
			width: 0,
			height: 0,
			borderLeft: '5px solid transparent',
			borderRight: '5px solid transparent',
			borderBottom: '5px solid #0779bf',
			fontSize: 0,
			lineHeight: 0,
			marginLeft: '75px'
		};
		if (this.props.position == 'left') {
			upArrowStyle.display = 'none';
		}
		var headerStyle = {
			borderTop: '1px solid ##2e6da4',
			borderLeft: '1px solid ##2e6da4',
			borderRight: '1px solid ##2e6da4',
			background: '#0779bf',
			color: '#fff',
			padding: '4px 8px'
		};
		var titleStyle = {
			borderLeft: '1px solid #efefef',
			borderRight: '1px solid #efefef',
			background: '#f8f8f8',
			padding: '4px 8px',
			fontWeight: 'bold'
		};
		var contentStyle = {
			borderLeft: '1px solid #efefef',
			borderRight: '1px solid #efefef',
			padding: '4px 8px'
		};
		var getQuestion = function() {
				return {
				__html: this.props.data.question
				};
		}.bind(this);
		var getAnswer = function() {
			return {
				__html: this.props.data.answer
			};
		}.bind(this);
		return (
			<div style={style}>
				<div style={upArrowStyle}></div>
				<div style={headerStyle}>{this.props.data.name}</div>
				<div style={titleStyle}>{I('Question')}</div>
				<div style={contentStyle} dangerouslySetInnerHTML={getQuestion()}></div>
				<div style={titleStyle}>{I('Answer')}</div>
				<div style={contentStyle} dangerouslySetInnerHTML={getAnswer()}></div>
			</div>
		);
	}
});

var LibraryTreeMouseOverButtons = createReactClass({
	getInitialState: function() {
		return {
			tabAppendState: {},
			answerAppended: false
		};
	},
	getDefaultProps: function() {
		return {
			showAppend: false,
			showInsert: false,
			showEdit: false,
			showRemove: false
		};
	},
	makeProp: function(prop, value) {
		//helper function to create an object by given named property (for IE 11),
		//refer #CS-409 - https://github.com/cention/cention-applications/commit/573e06d669e5fee2fd2b91c3042ee82bc271f19c
		var obj = {}
		obj[prop] = value
		return obj
	},
	handleAppend: function() {
		if (this.props.handleAppend) {
			this.props.handleAppend();
		}
		if(this.props.activeSessionId){
			var activeSessionId = this.props.activeSessionId;
			var tabAppendState = update(this.state.tabAppendState, {$merge: this.makeProp(
				activeSessionId, true
			)});
			this.setState({tabAppendState: tabAppendState})
		}else{
			this.setState({
				answerAppended: true
			});
		}
	},
	render: function() {
		var editStyle = {
			cursor: 'pointer',
			display: (this.props.showEdit ? 'auto' : 'none')
		};
		var removeStyle = {
			cursor: 'pointer',
			display: (this.props.showRemove ? 'auto' : 'none')
		};
		var appendStyle = {
			cursor: 'pointer',
			display: (this.props.showAppend ? 'auto' : 'none')
		};
		var insertStyle = {
			cursor: 'pointer',
			display: (this.props.showInsert ? 'auto' : 'none')
		};
		var style = {
			paddingLeft: '5px'
		};
		if (!this.props.show) {
			style.display = 'none';
		}
		var appendClassName = 'glyphicon glyphicon-plus-sign';
		if(typeof this.props.activeSessionId !== "undefined" &&
			this.props.activeSessionId in this.state.tabAppendState){
				appendClassName = 'glyphicon glyphicon-ok';
		}else{
			if(this.state.answerAppended){
				appendClassName = 'glyphicon glyphicon-ok';
			}
		}
		return (
			<span style={style}>
				<span
					className="glyphicon glyphicon-edit"
					aria-hidden="true"
					style={editStyle}
					title={I('Edit this node.')}
					onClick={this.props.handleEdit}></span>
				<span style={{paddingLeft: '5px'}}></span>
				<span
					className="glyphicon glyphicon-remove-sign"
					aria-hidden="true"
					style={removeStyle}
					title={I('Remove node from this container.')}
					onClick={this.props.handleRemove}></span>
				<span style={{paddingLeft: '5px'}}></span>
				<span
					className={appendClassName}
					aria-hidden="true"
					style={appendStyle}
					title={I('Append this answer to your current answer.')}
					onClick={this.handleAppend}></span>
				<span style={{paddingLeft: '5px'}}></span>
				<span
					className="glyphicon glyphicon-comment"
					aria-hidden="true"
					style={insertStyle}
					title={I('Insert this answer and replace your current answer.')}
					onClick={this.props.handleInsert}></span>
			</span>
		);
	}
});

var LibraryTreeContainer = createReactClass({
	getInitialState: function() {
		return {
			showButtons: false,
			showDropArea: false,
			subListExpanded: false
		};
	},
	getDefaultProps: function() {
		return {
			expanded: false,
			showEdit: false,
			showRemove: false,
			draggable: false
		};
	},
	componentWillMount: function() {
		if (this.props.expanded) {
			var state = this.state;
			state.subListExpanded = true;
			this.setState(state);
		}
	},
	componentWillReceiveProps: function(nextProps) {
		if (nextProps.expanded || nextProps.data.expanded) {
			this.setState({
				subListExpanded: true
			});
		}
	},
	showButtons: function() {
		this.setState({
			showButtons: true
		});
	},
	hideButtons: function() {
		this.setState({
			showButtons: false
		});
	},
	toggleSubList: function() {
		var state = this.state;
		state.subListExpanded = !state.subListExpanded;
		this.setState(state);
	},
	dragOver: function(event) {
		this.state.showDropArea = true;
		this.setState(this.state);
		event.preventDefault();
		event.stopPropagation();
	},
	dragEnter: function(event) {
		event.preventDefault();
		event.stopPropagation();
	},
	dragLeave: function(event) {
		this.state.showDropArea = false;
		this.setState(this.state);
		event.preventDefault();
	},
	drop: function(event) {
		if (this.props.handleDrop) {
			if (this.state.subListExpanded) {
				this.props.handleDrop(event, null, this.props.data);
			} else if (this.props.container) {
				this.props.handleDrop(event, this.props.data, this.props.container);
			}
		}
		this.state.showDropArea = false;
		this.setState(this.state);
		event.preventDefault();
		event.stopPropagation();
	},
	dragStart: function(event) {
		if (this.props.onDragStart) {
			this.props.onDragStart(event, this.props.data, this.props.container);
		}
		event.stopPropagation();
	},
	handleEdit: function() {
		this.props.handleEdit(this.props.data.id);
	},
	handleRemove: function() {
		this.props.handleRemove(this.props.data, this.props.container);
	},
	render: function() {
		var subListStyle = {display: 'none'};
		var toggleButtonClasses = 'glyphicon glyphicon-expand';
		if (this.state.subListExpanded) {
			subListStyle.display = 'block';
			toggleButtonClasses = 'glyphicon glyphicon-collapse-down';
		}
		var liStyle = {
			paddingTop: '4px',
			paddinBottom: '4px',
			paddingRight: '8px'
		};
		var spanStyle = {
			paddingLeft: '10px',
			cursor: 'pointer'
		};
		var dropAreaStyle = {
			display: 'none',
			height: '22px',
			backgroundColor: '#f2f2f2'
		};
		var showFirstItemDropArea = false;
		if (this.state.showDropArea) {
			if (this.state.subListExpanded) {
				showFirstItemDropArea = true;
			} else if (this.props.container) {
				dropAreaStyle.display = 'block';
			}
		}
		var draggable = false;
		if (this.props.draggable && this.props.container) {
			draggable = true;
		}
		return (
			<li
				style={liStyle}
				onMouseOver={this.showButtons}
				onMouseOut={this.hideButtons}
				onDragOver={this.dragOver}
				onDragEnter={this.dragEnter}
				onDragLeave={this.dragLeave}
				onDrop={this.drop}
				draggable={draggable}
				onDragStart={this.dragStart}
			>
				<div style={{marginLeft: '8px'}}>
					<span
						className={toggleButtonClasses}
						style={{cursor: 'pointer'}}
						aria-hidden="true"
						onClick={this.toggleSubList}></span>
					<span
						className={this.props.iconClasses}
						aria-hidden="true"
						style={spanStyle}
						onClick={this.toggleSubList}></span>
					<span style={spanStyle} onClick={this.toggleSubList}>
						{this.props.data.name}
					</span>
					<LibraryTreeMouseOverButtons
						show={this.state.showButtons}
						showEdit={this.props.showEdit}
						showRemove={this.props.showRemove}
						handleEdit={this.handleEdit}
						activeSessionId={this.props.activeSessionId}
						handleRemove={this.handleRemove} />
					<div style={subListStyle}>
						{this.props.renderItems(this.props.data.list, this.props.data, showFirstItemDropArea)}
					</div>
				</div>
				<div style={dropAreaStyle}></div>
			</li>
		);
	}
});

var LibraryTreeQuestion = createReactClass({
	timeGap: 0,
	getInitialState: function() {
		return {
			showButtons: false,
			showButtons: false,
			showPreview: false,
			showDropArea: false
		};
	},
	getDefaultProps: function() {
		return {
			showAppend: false,
			showInsert: false,
			showEdit: false,
			showRemove: false,
			enablePreview: true,
			previewPosition: 'left',
			previewMaxHeight: 0,
			previewShown: false,
			showTimeControlled: false,
			showChatShortcut: false
		};
	},
	showButtons: function() {
		this.setState({
			showButtons: true
		});
	},
	hideButtons: function() {
		this.setState({
			showButtons: false
		});
	},
	showPreview: function(event) {
		if (this.props.onShowPreview) {
			this.props.onShowPreview(event, this.props.data);
		} else {
			this.timeGap = new Date().getTime();
			if(this.state.showPreview == false &&
				this.props.previewShown == false){
				this.state.showPreview = true;
				this.setState(this.state);
				this.props.togglePreviewShown(true);
			}
		}
	},
	hidePreview: function(event) {
		if (this.props.onHidePreview) {
			this.props.onHidePreview();
		} else {
			if((new Date().getTime() - this.timeGap) < 250){
				return;
			}
			if(this.state.showPreview == true){
				this.state.showPreview = false;
				this.setState(this.state);
				this.props.togglePreviewShown(false);
			}
		}
	},
	dragOver: function(event) {
		this.state.showDropArea = true;
		this.state.showPreview = false;
		this.setState(this.state);
		event.preventDefault();
		event.stopPropagation();
	},
	dragEnter: function(event) {
		event.preventDefault();
		event.stopPropagation();
	},
	dragLeave: function(event) {
		this.state.showDropArea = false;
		this.setState(this.state);
		event.preventDefault();
	},
	drop: function(event) {
		if (this.props.handleDrop) {
			this.props.handleDrop(event, this.props.data, this.props.container);
		}
		this.state.showDropArea = false;
		this.setState(this.state);
		event.preventDefault();
		event.stopPropagation();
	},
	dragStart: function(event) {
		this.state.showPreview = false;
		this.setState(this.state);
		if (this.props.onDragStart) {
			this.props.onDragStart(event, this.props.data, this.props.container);
		}
		event.stopPropagation();
	},
	handleEdit: function() {
		this.props.handleEdit(this.props.data.id);
	},
	handleRemove: function() {
		this.props.handleRemove(this.props.data, this.props.container);
	},
	handleInsert: function() {
		this.props.handleInsert(this.props.data);
	},
	handleAppend: function() {
		this.props.handleAppend(this.props.data);
	},
	handleClick: function() {
		if (this.props.onClick) {
			this.props.onClick();
		}
	},
	renderShortcutSetup: function(shortcuts) {
		if(shortcuts.key != "") {
			var mod = [];
			if( shortcuts.alt ) {
				mod.push("Alt");
			}
			if( shortcuts.ctrl ) {
				mod.push("Ctrl");
			}
			if( shortcuts.meta ) {
				mod.push("Meta");
			}
			if( shortcuts.shift ) {
				mod.push("Shift");
			}
			var mods = mod.join(" + ");
			return "("+mods+" + "+shortcuts.key+")";
		}else {
			return "";
		}
	},
	render: function() {
		var style = {
			cursor: 'default',
			paddingTop: '4px',
			paddinBottom: '4px',
			paddingRight: '8px',
			backgroundColor: this.props.backgroundColor
		};
		var titleStyle = {
			paddingLeft: '10px'
		};
		var dropAreaStyle = {
			display: 'none',
			height: '22px',
			backgroundColor: '#f2f2f2'
		};
		var timeStyle = {
			paddingLeft: '5px'
		};
		var shortcutInfo = {
			key: this.props.data.keyboardShortcut,
			alt: (this.props.data.keyboardShortcutUseAlt ? true : false),
			ctrl: (this.props.data.keyboardShortcutUseCtrl ? true : false),
			meta: (this.props.data.keyboardShortcutUseMeta ? true : false),
			shift: (this.props.data.keyboardShortcutUseShift ? true : false)
		}
		if (this.state.showDropArea) {
			dropAreaStyle.display = 'block';
		}
		if (this.state.showPreview) {
			style.backgroundColor = '#f2f2f2';
		}
		if (!this.props.showTimeControlled || !this.props.data.timeControlled) {
			timeStyle.display = 'none';
		} else if (this.props.showTimeControlled && !this.props.data.active) {
			titleStyle.color = 'red';
		}
		var preview = null;
		if (this.props.enablePreview) {
			preview = (
				<LibraryTreeQuestionPreview
					position={this.props.previewPosition}
					maxHeight={this.props.previewMaxHeight}
					show={this.state.showPreview}
					data={this.props.data} />
			);
		}
		return (
			<li style={style}
				onMouseOver={this.showButtons}
				onMouseOut={this.hideButtons}
				onDragOver={this.dragOver}
				onDragEnter={this.dragEnter}
				onDragLeave={this.dragLeave}
				onDrop={this.drop}
				draggable={this.props.draggable}
				onDragStart={this.dragStart}
			>
				<div style={{marginLeft: '8px'}}>
					<span className="glyphicon glyphicon-tag" aria-hidden="true"></span>
					<span style={titleStyle} onClick={this.handleClick} onMouseEnter={this.showPreview} onMouseLeave={this.hidePreview}>
						{this.props.data.name}
						{preview}
					</span>
					<span className="libraryQuestionShortcut"> {this.props.showChatShortcut ? this.renderShortcutSetup(shortcutInfo) : ""} </span>
					<i className="fa fa-clock-o" style={timeStyle}></i>
					<LibraryTreeMouseOverButtons
						show={this.state.showButtons}
						showEdit={this.props.showEdit}
						showRemove={this.props.showRemove}
						showInsert={this.props.showInsert}
						showAppend={this.props.showAppend}
						activeSessionId={this.props.activeSessionId}
						handleEdit={this.handleEdit}
						handleRemove={this.handleRemove}
						handleInsert={this.handleInsert}
						handleAppend={this.handleAppend} />
				</div>
				<div style={dropAreaStyle}></div>
			</li>
		);
	}
});

var LibraryTree = createReactClass({
	getInitialState: function() {
		return {
			previewShown: false,
			list: []
		};
	},
	getDefaultProps: function() {
		return {
			library: 0,
			category: 0,
			search: '',
			searchSource: 'either',
			showAppend: false,
			showInsert: false,
			showEdit: false,
			showRemove: false,
			draggable: false,
			enablePreview: true,
			previewPosition: 'bottom',
			previewMaxHeight: 0,
			showTimeControlled: false,
			showChatShortcut: false
		};
	},
	componentWillMount: function() {
		this.load(this.props);
	},
	componentWillReceiveProps: function(nextProps) {
		if (nextProps.forceReload ||
			nextProps.library != this.props.library ||
			nextProps.category != this.props.category)
		{
			this.load(nextProps);
		}
	},
	shouldComponentUpdate: function(nextProps, nextState) {
		var update = false;
		if (nextProps.forceReload ||
			nextState.forceReload ||
			nextProps.library != this.props.library ||
			nextProps.category != this.props.category)
		{
			update = true;
		}
		if (nextState.forceReload) {
			this.setState({
				forceReload: false
			});
		}
		return update;
	},
	load: function(props) {
		if (parseInt(props.library, 10) > 0) {
			this.loadLibrary(props.library, props.search);
		} else if (parseInt(props.category, 10) > 0) {
			this.loadCategory(props.category, props.search);
		}
	},
	loadLibrary: function(id, search) {
		var url = webRoot + 'errand/library/tree/' + id;
		this.loadFromServer(url, search);
	},
	loadCategory: function(id, search) {
		var url = webRoot + 'errand/library/category/tree/' + id;
		this.loadFromServer(url, search);
	},
	loadFromServer: function(url, search) {
		var parameters = {};
		if (search) {
			parameters.search = search;
			parameters.source = 0; /* either */
			switch (this.props.searchSource) {
				case 'either':   parameters.source = 0; break; // either
				case 'both':     parameters.source = 1; break; // both
				case 'question': parameters.source = 2; break; // question
				case 'answer':   parameters.source = 3; break; // answer
			}
		}
		$.get(url, parameters).then(function(data) {
			this.setState({
				list: data.list,
				forceReload: true
			});
			if (this.props.onLoadEnd) {
				this.props.onLoadEnd(data.list);
			}
		}.bind(this));
	},
	togglePreviewShown: function(isShown){
		this.setState({previewShown: isShown});
	},
	renderItems: function(items, container, showDropArea) {
		if (!items) {
			return (<div></div>);
		}
		var renderedItems = items.map(function(item, i) {
			var bgColor = '#f9f9f9';
			if (i % 2 != 0) {
				bgColor = '#ffffff';
			}
			var showRemove = this.props.showRemove;
			if (!container) {
				showRemove = false;
			}
			switch (item.type) {
				case 'library':
					var key = (item.nodeId ? item.nodeId : 'root');
					var expanded = (key == 'root' ? true : false);
					return (
						<LibraryTreeContainer
							key={key}
							data={item}
							expanded={expanded}
							draggable={this.props.draggable}
							container={container}
							renderItems={this.renderItems}
							iconClasses="glyphicon glyphicon-book"
							showEdit={this.props.showEdit}
							showRemove={showRemove}
							handleEdit={this.props.handleLibraryEdit}
							handleRemove={this.props.handleLibraryRemove}
							handleDrop={this.props.handleDrop}
							activeSessionId={this.props.activeSessionId}
							onDragStart={this.props.onDragStart} />
					);
				case 'category':
					var key = (item.nodeId ? item.nodeId : 'root');
					return (
						<LibraryTreeContainer
							key={key}
							data={item}
							draggable={this.props.draggable}
							container={container}
							renderItems={this.renderItems}
							iconClasses="glyphicon glyphicon-folder-open"
							showEdit={this.props.showEdit}
							showRemove={showRemove}
							handleEdit={this.props.handleCategoryEdit}
							handleRemove={this.props.handleCategoryRemove}
							handleDrop={this.props.handleDrop}
							activeSessionId={this.props.activeSessionId}
							onDragStart={this.props.onDragStart} />
					);
				case 'question':
					return (
						<LibraryTreeQuestion
							key={item.nodeId}
							data={item}
							enablePreview={this.props.enablePreview}
							draggable={this.props.draggable}
							container={container}
							backgroundColor={bgColor}
							showEdit={this.props.showEdit}
							showRemove={this.props.showRemove}
							showInsert={this.props.showInsert}
							showAppend={this.props.showAppend}
							handleEdit={this.props.handleQuestionEdit}
							handleRemove={this.props.handleQuestionRemove}
							handleInsert={this.props.handleInsert}
							handleAppend={this.props.handleAppend}
							handleDrop={this.props.handleDrop}
							onDragStart={this.props.onDragStart}
							previewPosition={this.props.previewPosition}
							previewMaxHeight={this.props.previewMaxHeight}
							previewShown={this.state.previewShown}
							togglePreviewShown={this.togglePreviewShown}
							onShowPreview={this.props.onShowPreview}
							onHidePreview={this.props.onHidePreview}
							onClick={this.props.onSubjectClick}
							showTimeControlled={this.props.showTimeControlled}
							activeSessionId={this.props.activeSessionId}
							showChatShortcut={this.props.showChatShortcut} />
					);
			}
		}.bind(this));
		var style = {
			listStyleType: 'none',
			fontSize: '14px',
			marginTop: '0',
			paddingLeft: '16px'
		};
		var dropAreaStyle = {
			display: 'none',
			height: '22px',
			backgroundColor: '#f2f2f2',
			paddingTop: '4px',
			paddinBottom: '4px',
			paddingLeft: '8px',
			paddingRight: '8px'
		};
		if (showDropArea) {
			dropAreaStyle.display = 'block';
		}
		return (
			<ul style={style}>
				<li style={dropAreaStyle}></li>
				{renderedItems}
			</ul>
		);
	},
	render: function() {
		return this.renderItems(this.state.list, null);
	}
});
export default LibraryTree;
