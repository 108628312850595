import React, {Component} from 'react';
import { TXT_CLOSE_RECEIPT_EMAIL_INFO } from '../common/constants';
import CHelper from './CHelper';
import Ckeditor from './Ckeditor';
//feature
import Feature from './feature';

class CloseReceipt extends Component{
	constructor(props){
		super(props);
		this.state = {
			isORG: false,
			keepOrgCloseRcpt: true,
			features: {},
			languages: [],
			archiveImages: [],
			closeReceiptRules: {
				body:"",
				bodyForSocialMedia:"",
				emailAddress:"",
				htmlBody:"",
				send:false,
				subject:""
			}
		}
	}
	isValidEmail=(emailAddress)=>{
		var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		return re.test(emailAddress);
	}
	handleInputChange =( event )=>{
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		this.setState(prevState => ({
			closeReceiptRules: {
				...prevState.closeReceiptRules,
				[name]: value
			}
		}));
	}
	componentWillReceiveProps=(nextProps)=>{
		if(nextProps.languages != this.props.languages) {
			let cr = nextProps.closeReceiptRules;
			this.setState(prevState => ({
				keepOrgCloseRcpt: (typeof nextProps.keepOrgCloseRcp !== 'undefined' ? nextProps.keepOrgCloseRcp : false),
				languages: nextProps.languages,
				archiveImages: nextProps.areaFileArchive,
				features: nextProps.features,
				closeReceiptRules: {
					...prevState.closeReceiptRules,
					body: cr.body,
					bodyForSocialMedia: cr.bodyForSocialMedia,
					emailAddress: cr.emailAddress,
					htmlBody: cr.htmlBody,
					send: cr.send,
					subject: cr.subject
				}
			}));
		}
	}
	saveOrg = (event) =>{
		event.preventDefault();
		var c = this.state.closeReceiptRules;
		c.closeReceiptPresent = true;
		c.keepOrgCloseRcp = this.state.keepOrgCloseRcpt
		this.props.saveOrganization(CLOSERECEIPT, c);
	}
	handleCkeditorChange = (event) =>{
		this.setState(prevState => ({
			closeReceiptRules: {
				...prevState.closeReceiptRules,
				body: event.editor.getData(),
				htmlBody: event.editor.getData()
			}
		}));
	}
	handleSocialCkeditor = (event) =>{
		this.setState(prevState => ({
			closeReceiptRules: {
				...prevState.closeReceiptRules,
				bodyForSocialMedia: event.editor.getData()
			}
		}));
	}
	handleKeyDown =(e)=>{}
	handleInputCheck =(event) =>{
		this.setState({
			keepOrgCloseRcpt: event.target.checked
		});
	}
	render = () =>{
		let p = this.props.closeReceiptRules;
		let s = this.state;
		if(s.languages.length <= 0) {
			return <span>{I("Loading close receipt ...")}</span>;
		}else{
			let keepOrg = <div className="form-group-org">
									<label className="col-lg-2 col-sm-1">{I("Keep organization settings?")} :</label>
									<div className="col-lg-6 col-sm-4">
										<input type="checkbox" name="keepOrgCloseRcpt" onChange={this.handleInputCheck} checked={s.keepOrgCloseRcpt}/>
									</div>
									<div className="col-lg-1 col-sm-4"></div>
								</div>;
			if(this.props.usedFor === "org"){keepOrg = null;}
			if(this.props.usedFor === "area"){
				if(s.keepOrgCloseRcpt){
					return(<div  className="col-lg-9">
						<h4>{I("Close receipt")}</h4>
						{keepOrg}
						<div className="form-group-org">
							<div className="col-lg-5 textAlignRight">
								<button className="btn btn-warning btn-sm" type="button" onClick={this.props.cancelToOrg}>{I('Back')}</button>
								{this.saveButton()}
							</div>
						</div>
					</div>);
				}
			}
			var settingString = I("Enable area setting");
			if(this.props.usedFor === "org"){
				settingString = I("Enable organization setting");
			}

			return(<div  className="col-lg-9">
				<h4>{I("Close receipt")}</h4>
				{keepOrg}
				<div className="form-group-org">
					<label className="col-lg-2 col-sm-1">{settingString}:</label>
					<div className="col-lg-6 col-sm-4">
						<input type="checkbox" name="send" onChange={this.handleInputChange} checked={s.closeReceiptRules.send}/>
					</div>
					<div className="col-lg-1 col-sm-4">
						<CHelper previewMessage={I("Check if a receipt should be sent when answering multiple errands at the same time.") +"<br />"+ I("Close receipts are sent when you answer multiple errands at the same time. Only the selected errand will receive the actual answer the other errands will receive this receipt.")} pos="right"/>
					</div>
				</div>
				<div className="form-group-org">
					<label className="col-lg-2 col-sm-1">{I("Email receipt address")}:</label>
					<div className="col-lg-6 col-sm-4">
						<input name="emailAddress" className="form-control input-sm" type="text" value={s.closeReceiptRules.emailAddress} onChange={this.handleInputChange} />
					</div>
					<div className="col-lg-1 col-sm-4">
						<CHelper previewMessage={TXT_CLOSE_RECEIPT_EMAIL_INFO} pos="right"/>
					</div>
				</div>
				<div className="form-group-org">
					<label className="col-lg-2 col-sm-1">{I("Email receipt subject")}:</label>
					<div className="col-lg-6 col-sm-4">
						<input name="subject" className="form-control input-sm" type="text" value={s.closeReceiptRules.subject} onChange={this.handleInputChange} />
					</div>
					<div className="col-lg-1 col-sm-4"></div>
				</div>
				<div className="form-group-org">
					<label className="col-lg-2 col-sm-1">{I("Email receipt body")}:</label>
					<div className="col-lg-6 col-sm-4">
						<Ckeditor
							id={"closeReceiptBody"}
							ref={"closeReceiptBody"}
							locationHash={window.location.hash}
							defaultFontFamily={s.features["rich-text.default-font-family"]}
							defaultFontSize={s.features["rich-text.default-font-size"]}
							spellLanguages={s.languages}
							defaultContent={s.closeReceiptRules.body}
							showResize={false}
							simpleToolbar={false}
							fileArchiveImages={s.archiveImages}
							onChange={this.handleCkeditorChange}
							onKeydown={this.handleKeyDown}
						/>
					</div>
					<div className="col-lg-1 col-sm-4">
						<CHelper previewMessage={I("When answering multiple email errands at the same time this together with the answer is what will be sent to the sender of those errands which were not the errand that was specifically answered.")
						+ "<br />"
						+ I("You can use the following templates to put customer specific information in the receipt:")
						+ "<ul>"
							+ "<li><b>{ERRANDID}</b><br />"
							+ I("Errand number assigned to the new email.")
							+ "</li>"
							+ "<li><b>[name]</b><br />"
							+ I("Customer's name. If no name was specified in incoming email then [name] will be replaced by email address.")
							+ "</li>"
							+ "<li><b>[email]</b><br />"
							+ I("Customer's email address.")
							+ "</li>"
						+"</ul>"
						+ I("Example") + ":<br />"
						+ "<i>"
							+ I("Hi [name],")
							+ "<br />"
							+ I("This is an automatic repsonse that was generated when your errand {ERRANDID} was closed at the same time as another errand was answered.")
							+ "<br />"
							+ I("Best regards")
							+ "<br />[signature name]<br />"
							+ I("The support team")
						+ "</i>"
						} pos="right"/>
					</div>
				</div>
				<div className="form-group-org">
					<label className="col-lg-2 col-sm-1">{I("Social media receipt body")}:</label>
					<div className="col-lg-6 col-sm-4">
						<Ckeditor
						id={"closeReceiptBodyForSocialMedia"}
						ref={"closeReceiptBodyForSocialMedia"}
						locationHash={window.location.hash}
						locationHash={window.location.hash}
						defaultFontFamily={s.features["rich-text.default-font-family"]}
						defaultFontSize={s.features["rich-text.default-font-size"]}
						spellLanguages={s.languages}
						defaultContent={s.closeReceiptRules.bodyForSocialMedia}
						showResize={false}
						simpleToolbar={true}
						fileArchiveImages={s.archiveImages}
						onChange={this.handleSocialCkeditor}
						onKeydown={this.handleKeyDown}
					/>
					</div>
					<div className="col-lg-1 col-sm-4">
						<CHelper previewMessage={I("When answering multiple errands at the same time and among those are both email errands and social media errands (e.g. Facebook and Twitter) and the errand specifically answered is an email errand then this is what the sender of the social media errands will receive.")
						+ "<br />"
						+ I("You can use the following templates to put customer specific information in the receipt:")
						+ "<ul>"
							+ "<li><b>{ERRANDID}</b><br />"
							+ I("Errand number assigned to the new email.")
							+ "</li>"
							+ "<li><b>[name]</b><br />"
							+ I("Customer's name. If no name was specified in incoming email then [name] will be replaced by email address.")
							+ "</li>"
							+ "<li><b>[email]</b><br />"
							+ I("Customer's email address.")
							+ "</li>"
						+"</ul>"
						+ I("Example") + ":<br />"
						+ "<i>"
							+ I("Your question has been answered in the email {ERRANDID} you sent us. Let us know if there is anything else we can help you with.")
						+ "</i>"
						} pos="right"/>
					</div>
				</div>
				<div className="form-group-org">
					<div className="col-lg-5 textAlignRight">
						<button className="btn btn-warning btn-sm" type="button" onClick={this.props.cancelToOrg}>{I('Back')}</button>
						{this.saveButton()}
					</div>
				</div>
			</div>);
		}
	}
	saveButton = () =>{
		if(!this.props.saving){
			return <button className="btn btn-primary btn-sm" type="button" onClick={this.saveOrg}>{I('Save')}</button>
		}
		return <button className="btn btn-primary btn-sm" type="button">{I('Saving...')}</button>
	}
}
export default CloseReceipt;
