import React, {Component} from 'react';
import update from 'react-addons-update';
import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';

import {
	DEFAULT_SATISFACTION_RATING_TEXT,
	feedbackActions,
	feedbackFontColors,
	feedbackFontFamilies,
	feedbackFontSizes,
	feedbackFontStyles,
	feedbackOutgoingPosition
} from '../common/constants'
import CHelper from './CHelper';
import Ckeditor from './Ckeditor';
import FileUploader from './FileUploader';
import BootstrapModal from './BootstrapModal';
import AdminAction from '../flux/actions/adminActions';
//feature
import Feature from './feature';

const THANKYOU = 0, CONTACTME = 1, REGISTERED = 2, SENT = 3;
export default class FeedbackSettings extends Component{
	constructor(props){
		super(props);
		this.state = {
			isORG: false,
			activeTab: THANKYOU,
			features: {},
			languages: [],
			archiveImages: [],
			feedback: {
				actionType1:0,
				actionType2:0,
				actionType3:0,
				actionType4:0,
				actionType5:0,
				areaFeedbackCheck:false,
				areaForReplies:[],
				contactButton:"",
				contactByEmail:"",
				contactByPhone:"",
				contactImagePosition:0,
				contactSentHtmlMessageContent:"",
				contactSentTextMessageContent:" ",
				contactSentUploadFileName:"",
				contactSentUploadFileUrl:"",
				contactSentUploadType:0,
				contactTextMessageContent:" ",
				contactSentUploadID:"",
				contactSentUploadFileContent:"",
				contactHtmlMessageContent:"",
				contactUploadFileName:"",
				contactUploadFileUrl:"",
				contactUploadType:0,
				contactUploadID:"",
				contactUploadFileContent:"",
				defaultSatisfactionCheck:false,
				editAnswer1:"",
				editAnswer2:"",
				editAnswer3:"",
				editAnswer4:"",
				editAnswer5:"",
				editAnswerIdentifier1:"",
				editAnswerIdentifier2:"",
				editAnswerIdentifier3:"",
				editAnswerIdentifier4:"",
				editAnswerIdentifier5:"",
				editQuestionHtml:"",
				editQuestionText:" ",
				emailPosition:0,
				errandContainarea:0,
				externalUrl:"",
				fontColor:"black",
				fontFamily:"",
				fontSize:10,
				fontStyle:"normal",
				keepOrgFeedback: true,
				registeredHtmlMessageContent:"",
				registeredImagePosition:0,
				registeredTextMessageContent:" ",
				registeredUploadFileName:"",
				registeredUploadFileUrl:"",
				registeredUploadtype:0,
				registeredUploadFileContent:"",
				registeredUploadID:"",
				contactSentImagePosition:0,
				thankYouHtmlMessageContent:"",
				thankYouTextMessageContent:" ",
				thankYouUploadFileName:"",
				thankYouUploadFileUrl:"",
				thankYouUploadType:0,
				thankYouUploadID:"",
				thankYouUploadFileContent:"",
				thankYouImagePosition:0,
			}
		}
	}
	componentWillReceiveProps=(nextProps)=>{
		if(nextProps.languages != this.props.languages) {
			let fd = nextProps.feedback;
			this.setState(prevState => ({languages: nextProps.languages,
				archiveImages: nextProps.areaFileArchive,
				features: nextProps.features,
				feedback: {
					...prevState.feedback,
					actionType1:fd.actionType1,
					actionType2:fd.actionType2,
					actionType3:fd.actionType3,
					actionType4:fd.actionType4,
					actionType5:fd.actionType5,
					areaFeedbackCheck:fd.areaFeedbackCheck,
					areaForReplies:fd.areaForReplies,
					contactButton:fd.contactButton,
					contactByEmail:fd.contactByEmail,
					contactByPhone:fd.contactByPhone,
					contactHtmlMessageContent:fd.contactHtmlMessageContent,
					contactImagePosition:fd.contactImagePosition,
					contactSentHtmlMessageContent:fd.contactSentHtmlMessageContent,
					contactSentTextMessage:fd.contactSentTextMessageContent,
					contactSentUploadFileName:fd.contactSentUploadFileName,
					contactSentUploadFileUrl:fd.contactSentUploadFileUrl,
					contactSentUploadType:fd.contactSentUploadType,
					contactSentUploadID:fd.contactSentUploadID,
					contactSentUploadFileContent:fd.contactSentUploadFileContent,
					contactTextMessageContent:fd.contactTextMessageContent,
					contactUploadFileName:fd.contactUploadedFileName,
					contactUploadFileUrl:fd.contactUploadFileUrl,
					contactUploadType:fd.contactUploadType,
					contactUploadID:fd.contactUploadID,
					contactUploadFileContent:fd.contactUploadFileContent,
					defaultSatisfactionCheck: fd.defaultSatisfactionCheck,
					editAnswer1:fd.editAnswer1,
					editAnswer2:fd.editAnswer2,
					editAnswer3:fd.editAnswer3,
					editAnswer4:fd.editAnswer4,
					editAnswer5:fd.editAnswer5,
					editAnswerIdentifier1:fd.editAnswerIdentifier1,
					editAnswerIdentifier2:fd.editAnswerIdentifier2,
					editAnswerIdentifier3:fd.editAnswerIdentifier3,
					editAnswerIdentifier4:fd.editAnswerIdentifier4,
					editAnswerIdentifier5:fd.editAnswerIdentifier5,
					editQuestionHtml:fd.editQuestionHtml,
					editQuestionText:fd.editQuestionText,
					emailPosition:fd.emailPosition,
					errandContainarea:fd.errandContainarea,
					externalUrl:fd.externalUrl,
					fontColor:fd.fontColor,
					fontFamily:fd.fontFamily,
					fontSize:fd.fontSize,
					fontStyle:fd.fontStyle,
					keepOrgFeedback: fd.keepOrgFeedback,
					registeredHtmlMessageContent:fd.registeredHtmlMessageContent,
					registeredImagePosition:fd.registeredImagePosition,
					registeredTextMessageContent:fd.registeredTextMessageContent,
					registeredUploadFileName:fd.registeredUploadFileName,
					registeredUploadFileUrl:fd.registeredUploadFileUrl,
					registeredUploadtype:fd.registeredUploadtype,
					registeredUploadFileContent:fd.registeredUploadFileContent,
					registeredUploadID:fd.registeredUploadID,
					contactSentImagePosition:fd.contactSentImagePosition,
					thankYouHtmlMessageContent:fd.thankYouHtmlMessageContent,
					thankYouTextMessageContent:fd.thankYouTextMessageContent,
					thankYouUploadFileName:fd.thankYouUploadFileName,
					thankYouUploadFileUrl:fd.thankYouUploadFileUrl,
					thankYouUploadType:fd.thankYouUploadType,
					thankYouUploadID:fd.thankYouUploadID,
					thankYouUploadFileContent:fd.thankYouUploadFileContent,
					thankYouImagePosition:fd.thankYouImagePosition
				}
			}));
		}
	}
	saveOrg = (event) =>{
		event.preventDefault();
		var c = this.state.feedback;
		c.feedbackSettingsPresent = true;
		this.props.saveOrganization(FEEDBACK, c);
	}
	handleCkeditorChange = (event) =>{
		this.setState(prevState => ({
			feedback: {
				...prevState.feedback,
				editQuestionHtml: event.editor.getData()
			}
		}));
	}
	handleFileUpload = (data) =>{
		this.setState(prevState => ({
			feedback: {
				...prevState.feedback,
				[data.prefix+"UploadID"]: data.id,
				[data.prefix+"UploadFileName"]: data.value,
				[data.prefix+"UploadFileContent"]: data.download,
				[data.prefix+"UploadFileUrl"]: data.url,
			}
		}));
	}
	handleRemoveFile = (prefix) => {
		const newObj = {
			[prefix+"UploadID"]: "",
			[prefix+"UploadFileName"]: "",
			[prefix+"UploadFileContent"]: "",
			[prefix+"UploadFileUrl"]: "",
		};
		this.setState(prevState => ({
			feedback: {
				...prevState.feedback,
				[prefix+"UploadID"]: "",
				[prefix+"UploadFileName"]: "",
				[prefix+"UploadFileContent"]: "",
				[prefix+"UploadFileUrl"]: "",
			}
		}));
		const newData = update(this.state.feedback,
			{$merge: newObj});
		newData.feedbackSettingsPresent = true;
		if(this.props.usedFor === "area"){
			AdminAction.saveAdminArea(this.props.id, newData)
			.then(function(data){
				console.log("Area feedback settings saved !");
			}.bind(this));
		} else if(this.props.usedFor === "org"){
			AdminAction.saveAdminOrganisation(this.props.id, newData)
			.then(function(data){
				console.log("Organisation feedback settings saved !");
			}.bind(this));
		}
	}
	handleKeyDown =(e)=>{}
	toggle =( tab )=>{
		if(this.state.activeTab !== tab) {
			this.setState({activeTab: tab});
		}
	}
	handleInputChange =( event )=>{
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		this.setState(prevState => ({
			feedback: {
				...prevState.feedback,
				[name]: value
			}
		}));
		let answerOption="";
		switch(name){
			case 'actionType1':
				answerOption = "editAnswer1";
				break;
			case 'actionType2':
				answerOption = "editAnswer2";
				break;
			case 'actionType3':
				answerOption = "editAnswer3";
				break;
			case 'actionType4':
				answerOption = "editAnswer4";
				break;
			case 'actionType5':
				answerOption = "editAnswer5";
				break;
			default:
		}
		if(answerOption.length > 0 && value == 0){
			this.setState(prevState => ({
				feedback: {
					...prevState.feedback,
					[answerOption]: ""
				}
			}));
		}
	}
	handleDefaultSatisfaction = (event) => {
		const target = event.target;
		const value = target.checked;
		const name = target.name;
		let defaultAns = "", defaultAct = 0;
		if (value) {
			defaultAns = DEFAULT_SATISFACTION_RATING_TEXT;
			defaultAct = 2;
		}
		this.setState(prevState => ({
			feedback: {
				...prevState.feedback,
				[name]: value,
				editAnswer1:defaultAns,
				editAnswer2:defaultAns,
				editAnswer3:defaultAns,
				editAnswer4:defaultAns,
				editAnswer5:defaultAns,
				actionType1:defaultAct,
				actionType2:defaultAct,
				actionType3:defaultAct,
				actionType4:defaultAct,
				actionType5:defaultAct,
				editAnswerIdentifier1:"",
				editAnswerIdentifier2:"",
				editAnswerIdentifier3:"",
				editAnswerIdentifier4:"",
				editAnswerIdentifier5:"",
				externalUrl:""
			}
		}));
	}
	handleInputCheck =(event) =>{
		const checked = event.target.checked;
		this.setState(prevState => ({
			feedback: {
				...prevState.feedback,
				keepOrgFeedback: checked,
				areaFeedbackCheck: !checked
			}
		}));
	}
	render = () =>{
		let p = this.props;
		let st = this.state;
		let s = this.state.feedback;
		if(!s.fontFamily){
			s.fontFamily = 'Verdana';
		}
		if(!s.fontSize){
			s.fontSize = '10';
		}
		if(!s.fontColor){
			s.fontColor = 'Black';
		}
		if(!s.fontStyle){
			s.fontStyle = 'Normal';
		}

		let uploadUrl = p.id + "/uploadTempl";
		if(p.isArea == true){
			uploadUrl = "admin/area/" + uploadUrl;
		} else {
			uploadUrl = "admin/org/" + uploadUrl;
		}
		if(st.languages.length <= 0) {
			return <span>{I("Loading close receipt ...")}</span>;
		}else{
			let fontFamilies = feedbackFontFamilies;
			let fontColors = feedbackFontColors;
			let fontStyles = feedbackFontStyles;
			let fontSizes = feedbackFontSizes;
			let outgoingPositions = feedbackOutgoingPosition;
			let actions = feedbackActions;
			let orgFeedback = <div className="form-group-org">
									<label className="col-lg-2 col-sm-1">{I("Keep organization settings?")} :</label>
									<div className="col-lg-6 col-sm-4">
										<input type="checkbox" name="keepOrgFeedback" onChange={this.handleInputCheck} checked={s.keepOrgFeedback}/>
									</div>
									<div className="col-lg-1 col-sm-4"></div>
								</div>;
			if(this.props.usedFor === "org"){orgFeedback = null;}
			if(this.props.usedFor === "area"){
				if(s.keepOrgFeedback){
					return(<div  className="col-lg-8">
						<h4>{I("Feedback settings")}</h4>
						{orgFeedback}
						<div className="form-group-org">
							<div className="col-lg-5 textAlignRight">
								<button className="btn btn-warning btn-sm" type="button" onClick={this.props.cancelToOrg}>{I('Back')}</button>
								{this.saveButton()}
							</div>
						</div>
					</div>);
				}
			}
			return(<div  className="col-lg-8">
				<h4>{I("Feedback settings")}</h4>
				{orgFeedback}
				<div className="form-group-org">
					<label className="col-lg-2 col-sm-1">{I("Enable Satisfaction meter")}:</label>
					<div className="col-lg-6 col-sm-4">
						<input type="checkbox" name="areaFeedbackCheck" onChange={this.handleInputChange} checked={s.areaFeedbackCheck}/>
					</div>
					<div className="col-lg-1 col-sm-4"></div>
				</div>
				<div className="form-group-org">
					<label className="col-lg-2 col-sm-1">{I("Question")}:<span className="mandatory">* </span></label>
					<div className="col-lg-6 col-sm-4">
						<Ckeditor
							id={"Question"}
							ref={"Question"}
							locationHash={window.location.hash}
							defaultFontFamily={st.features["rich-text.default-font-family"]}
							defaultFontSize={st.features["rich-text.default-font-size"]}
							defaultContent={s.editQuestionHtml}
							spellLanguages={st.languages}
							showResize={false}
							simpleToolbar={false}
							fileArchiveImages={st.archiveImages}
							onChange={this.handleCkeditorChange}
							onKeydown={this.handleKeyDown}
						/>
					</div>
					<div className="col-lg-1 col-sm-4"></div>
				</div>
				<div className="form-group-org">
					<label className="col-lg-2 col-sm-1">{I("Outgoing position")}:</label>
					<div className="col-lg-2 col-sm-4">
						<select name="emailPosition" value={s.emailPosition} onChange={this.handleInputChange} className="form-control input-sm">
							{outgoingPositions.map((f,i) =><option key={i} value={f.id}>{f.value}</option>)}
						</select>
					</div>
					<div className="col-lg-4 col-sm-4"></div>
				</div>
				<div className="form-group-org">
					<label className="col-lg-2 col-sm-1">{I("Font")}:</label>
					<div className="col-lg-2 col-sm-4">
						<select name="fontFamily" value={s.fontFamily} onChange={this.handleInputChange} className="form-control input-sm">
							{fontFamilies.map((f,i) =><option key={i} value={f.id}>{f.value}</option>)}
						</select>
					</div>
					<div className="col-lg-4 col-sm-4"></div>
				</div>
				<div className="form-group-org">
					<label className="col-lg-2 col-sm-1">{I("Font Size")}:</label>
					<div className="col-lg-2 col-sm-4">
						<select name="fontSize" value={s.fontSize} onChange={this.handleInputChange} className="form-control input-sm">
							{fontSizes.map((f,i) =><option key={i} value={f.id}>{f.value}</option>)}
						</select>
					</div>
					<div className="col-lg-4 col-sm-4"></div>
				</div>
				<div className="form-group-org">
					<label className="col-lg-2 col-sm-1">{I("Font Color")}:</label>
					<div className="col-lg-2 col-sm-4">
						<select name="fontColor" value={s.fontColor} onChange={this.handleInputChange} className="form-control input-sm">
							{fontColors.map((f,i) =><option key={i} value={f.id}>{f.value}</option>)}
						</select>
					</div>
					<div className="col-lg-4 col-sm-4"></div>
				</div>
				<div className="form-group-org">
					<label className="col-lg-2 col-sm-1">{I("Font Style")}:</label>
					<div className="col-lg-2 col-sm-4">
						<select name="fontStyle" value={s.fontStyle} onChange={this.handleInputChange} className="form-control input-sm">
							{fontStyles.map((f,i) =><option key={i} value={f.id}>{f.value}</option>)}
						</select>
					</div>
					<div className="col-lg-2 col-sm-4"></div>
				</div>
				<div className="form-group-org">
					<label className="col-lg-2 col-sm-1">{I("Use default meter")}:</label>
					<div className="col-lg-6 col-sm-4">
						<input type="checkbox" name="defaultSatisfactionCheck" onChange={this.handleDefaultSatisfaction} checked={s.defaultSatisfactionCheck}/>
					</div>
					<div className="col-lg-1 col-sm-4"></div>
				</div>
				<div className="form-group-org">
					<label className="col-lg-2 col-sm-1">{I("Option")}1:</label>
					<div className="col-lg-3 col-sm-4">
						<input disabled={s.defaultSatisfactionCheck} name="editAnswer1" className="form-control input-sm" type="text" value={s.editAnswer1} onChange={this.handleInputChange} />
					</div>
					<div className="col-lg-2 col-sm-4">
						<select disabled={s.defaultSatisfactionCheck} name="actionType1" value={s.actionType1} onChange={this.handleInputChange} className="form-control input-sm">
							{actions.map((f,i) =><option key={i} value={f.id}>{f.value}</option>)}
						</select>
					</div>
					<div className="col-lg-1 col-sm-4"></div>
				</div>
				<div className="form-group-org">
					<label className="col-lg-2 col-sm-1">{I("Option Identifier")}1:</label>
					<div className="col-lg-5 col-sm-4">
						<input disabled={s.defaultSatisfactionCheck} name="editAnswerIdentifier1" className="form-control input-sm" type="text" value={s.editAnswerIdentifier1} onChange={this.handleInputChange} />
					</div>
					<div className="col-lg-1 col-sm-4"></div>
				</div>
				<div className="form-group-org">
					<label className="col-lg-2 col-sm-1">{I("Option")} 2:</label>
					<div className="col-lg-3 col-sm-4">
						<input disabled={s.defaultSatisfactionCheck} name="editAnswer2" className="form-control input-sm" type="text" value={s.editAnswer2} onChange={this.handleInputChange} />
					</div>
					<div className="col-lg-2 col-sm-4">
						<select disabled={s.defaultSatisfactionCheck} name="actionType2" value={s.actionType2} onChange={this.handleInputChange} className="form-control input-sm">
							{actions.map((f,i) =><option key={i} value={f.id}>{f.value}</option>)}
						</select>
					</div>
					<div className="col-lg-1 col-sm-4"></div>
				</div>
				<div className="form-group-org">
					<label className="col-lg-2 col-sm-1">{I("Option Identifier")} 2:</label>
					<div className="col-lg-5 col-sm-4">
						<input disabled={s.defaultSatisfactionCheck} name="editAnswerIdentifier2" className="form-control input-sm" type="text" value={s.editAnswerIdentifier2} onChange={this.handleInputChange} />
					</div>
					<div className="col-lg-1 col-sm-4"></div>
				</div>
				<div className="form-group-org">
					<label className="col-lg-2 col-sm-1">{I("Option")} 3:</label>
					<div className="col-lg-3 col-sm-4">
						<input disabled={s.defaultSatisfactionCheck} name="editAnswer3" className="form-control input-sm" type="text" value={s.editAnswer3} onChange={this.handleInputChange} />
					</div>
					<div className="col-lg-2 col-sm-4">
						<select disabled={s.defaultSatisfactionCheck} name="actionType3" value={s.actionType3} onChange={this.handleInputChange} className="form-control input-sm">
							{actions.map((f,i) =><option key={i} value={f.id}>{f.value}</option>)}
						</select>
					</div>
					<div className="col-lg-1 col-sm-4"></div>
				</div>
				<div className="form-group-org">
					<label className="col-lg-2 col-sm-1">{I("Option Identifier")} 3:</label>
					<div className="col-lg-5 col-sm-4">
						<input disabled={s.defaultSatisfactionCheck} name="editAnswerIdentifier3" className="form-control input-sm" type="text" value={s.editAnswerIdentifier3} onChange={this.handleInputChange} />
					</div>
					<div className="col-lg-1 col-sm-4"></div>
				</div>
				<div className="form-group-org">
					<label className="col-lg-2 col-sm-1">{I("Option")} 4:</label>
					<div className="col-lg-3 col-sm-4">
						<input disabled={s.defaultSatisfactionCheck} name="editAnswer4" className="form-control input-sm" type="text" value={s.editAnswer4} onChange={this.handleInputChange} />
					</div>
					<div className="col-lg-2 col-sm-4">
						<select disabled={s.defaultSatisfactionCheck} name="actionType4" value={s.actionType4} onChange={this.handleInputChange} className="form-control input-sm">
							{actions.map((f,i) =><option key={i} value={f.id}>{f.value}</option>)}
						</select>
					</div>
					<div className="col-lg-1 col-sm-4"></div>
				</div>
				<div className="form-group-org">
					<label className="col-lg-2 col-sm-1">{I("Option Identifier")} 4:</label>
					<div className="col-lg-5 col-sm-4">
						<input disabled={s.defaultSatisfactionCheck} name="editAnswerIdentifier4" className="form-control input-sm" type="text" value={s.editAnswerIdentifier4} onChange={this.handleInputChange} />
					</div>
					<div className="col-lg-1 col-sm-4"></div>
				</div>
				<div className="form-group-org">
					<label className="col-lg-2 col-sm-1">{I("Option")} 5:</label>
					<div className="col-lg-3 col-sm-4">
						<input disabled={s.defaultSatisfactionCheck} name="editAnswer5" className="form-control input-sm" type="text" value={s.editAnswer5} onChange={this.handleInputChange} />
					</div>
					<div className="col-lg-2 col-sm-4">
						<select disabled={s.defaultSatisfactionCheck} name="actionType5" value={s.actionType5} onChange={this.handleInputChange} className="form-control input-sm">
							{actions.map((f,i) =><option key={i} value={f.id}>{f.value}</option>)}
						</select>
					</div>
					<div className="col-lg-1 col-sm-4"></div>
				</div>
				<div className="form-group-org">
					<label className="col-lg-2 col-sm-1">{I("Option Identifier")} 5:</label>
					<div className="col-lg-5 col-sm-4">
						<input disabled={s.defaultSatisfactionCheck} name="editAnswerIdentifier5" className="form-control input-sm" type="text" value={s.editAnswerIdentifier5} onChange={this.handleInputChange} />
					</div>
					<div className="col-lg-1 col-sm-4"></div>
				</div>
				<div className="form-group-org">
					<label className="col-lg-2 col-sm-1">{I("External Url")}:</label>
					<div className="col-lg-5 col-sm-4">
						<input disabled={s.defaultSatisfactionCheck} name="externalUrl" className="form-control input-sm" type="text" value={s.externalUrl} onChange={this.handleInputChange} />
					</div>
					<div className="col-lg-1 col-sm-4"><CHelper previewMessage={I("If an external page is used for the satisfaction meter, fill in the URL here.")} pos="right"/></div>
				</div>
				<div className="form-group-org">
					<div className="col-lg-12 col-sm-4">
						<h4>{I("Settings for different page templates")}:</h4>
						<Nav tabs>
							<NavItem className={this.state.activeTab === THANKYOU ? "active" : ""}>
								<NavLink onClick={() =>this.toggle(THANKYOU)}>{I("Thank you")}</NavLink>
							</NavItem>
							<NavItem className={this.state.activeTab === CONTACTME ? "active" : ""}>
								<NavLink onClick={() =>this.toggle(CONTACTME)}>{I("Contact me")}</NavLink>
							</NavItem>
							<NavItem className={this.state.activeTab === REGISTERED ? "active" : ""}>
								<NavLink onClick={() =>this.toggle(REGISTERED)}>{I("Already registered")}</NavLink>
							</NavItem>
							<NavItem className={this.state.activeTab === SENT ? "active" : ""}>
								<NavLink onClick={() =>this.toggle(SENT)}>{I("Successfully sent")}</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={this.state.activeTab}>
							<TabPane tabId={THANKYOU}>
								<PageTemplate
									tab={THANKYOU}
									features={st.features}
									languages={st.languages}
									body={s.thankYouHtmlMessageContent}
									archiveImages={st.archiveImages}
									tabFormEvent={this.handleTabFormEvent}
									CKChange={this.handleCK}
									uploadUrl={uploadUrl}
									handleFileUpload={this.handleFileUpload}
									handleRemoveFile={this.handleRemoveFile}
									prefix="thankYou"
									{...s}
								/>
							</TabPane>
							<TabPane tabId={CONTACTME}>
								<PageTemplate
									tab={CONTACTME}
									areaForReplies={st.areaForReplies}
									features={st.features}
									languages={st.languages}
									body={s.contactHtmlMessageContent}
									archiveImages={st.archiveImages}
									tabFormEvent={this.handleTabFormEvent}
									CKChange={this.handleCK}
									uploadUrl={uploadUrl}
									handleFileUpload={this.handleFileUpload}
									handleRemoveFile={this.handleRemoveFile}
									prefix="contact"
									{...s}
								/>
							</TabPane>
							<TabPane tabId={REGISTERED}>
								<PageTemplate
									tab={REGISTERED}
									features={st.features}
									languages={st.languages}
									body={s.registeredHtmlMessageContent}
									archiveImages={st.archiveImages}
									tabFormEvent={this.handleTabFormEvent}
									CKChange={this.handleCK}
									uploadUrl={uploadUrl}
									handleFileUpload={this.handleFileUpload}
									handleRemoveFile={this.handleRemoveFile}
									prefix="registered"
									{...s}
								/>
							</TabPane>
							<TabPane tabId={SENT}>
								<PageTemplate
									tab={SENT}
									features={st.features}
									languages={st.languages}
									body={s.contactSentHtmlMessageContent}
									archiveImages={st.archiveImages}
									tabFormEvent={this.handleTabFormEvent}
									CKChange={this.handleCK}
									uploadUrl={uploadUrl}
									handleFileUpload={this.handleFileUpload}
									handleRemoveFile={this.handleRemoveFile}
									prefix="contactSent"
									{...s}
								/>
							</TabPane>
						</TabContent>
					</div>
				</div>
				<div className="form-group-org">
					<div className="col-lg-5 textAlignRight">
						<button className="btn btn-warning btn-sm" type="button" onClick={this.props.cancelToOrg}>{I('Back')}</button>
						{this.saveButton()}
					</div>
				</div>
			</div>)
		}
	}
	saveButton = () =>{
		if(!this.props.saving){
			return <button className="btn btn-primary btn-sm" type="button" onClick={this.saveOrg}>{I('Save')}</button>
		}
		return <button className="btn btn-primary btn-sm" type="button">{I('Saving...')}</button>
	}
	handleCK = (tab, val) =>{
		let tabCKContent = "";
		switch(tab){
			case THANKYOU:
				tabCKContent = "thankYouHtmlMessageContent";
				break;
			case CONTACTME:
				tabCKContent = "contactHtmlMessageContent";
				break;
			case REGISTERED:
				tabCKContent = "registeredHtmlMessageContent";
				break;
			case SENT:
				tabCKContent = "contactSentHtmlMessageContent";
				break;
			default:
		}
		if(tabCKContent !== ""){
			this.setState(prevState => ({
				feedback: {
					...prevState.feedback,
					[tabCKContent]: val
				}
			}));
		}
	}

	handleTabFormEvent = (tab, fieldName, value) =>{
		this.setState(prevState => ({
			feedback: {
				...prevState.feedback,
				[fieldName]: value
			}
		}));
	}
}

class PageTemplate extends Component{
	constructor(props){
		super(props);
		this.state={
			hidePreview: true,
			thankYouUploadType: false,
			contactUploadType: false,
			registeredUploadType: false,
			contactSentUploadType: false,
		}
	}
	componentDidMount = ()=>{
		let p = this.props;
		this.setState({
			[p.prefix + "UploadType"]: (parseInt(p[p.prefix + "UploadType"],10) === 1 ? true : false)
		});
	}
	componentWillReceiveProps = (nextProps)=>{
		if(nextProps !== this.props){
			let p = nextProps;
			this.setState({
				[p.prefix + "UploadType"]: (parseInt(p[p.prefix + "UploadType"], 10) === 1 ? true : false)
			});
		}
	}
	handleKeyDown =()=>{}
	handleCkeditorChange = (event) =>{
		this.props.CKChange(this.props.tab, event.editor.getData());
	}
	handleInputChange = (event) =>{
		this.props.tabFormEvent(this.props.tab, event.target.name, event.target.value);
	}
	preview = () =>{
		this.setState({
			hidePreview: false,
		});
	}
	getPreviewBody = () =>{
		let p = this.props;
		let uploadType = this.state[p.prefix + "UploadType"]
		let messageContent = p[p.prefix + "HtmlMessageContent"]
		let fileContent = p[p.prefix + "UploadFileContent"]
		let imagePosition = p[p.prefix + "ImagePosition"]
		if(uploadType == 0){
			var style = {
				maxWidth: "200px",
				maxHeight: "200px"
			};
			if(imagePosition === 0){
				return (
					<div>
						<div id="feedback_template_preview_content">
							<img style={style} src={p[p.prefix + "UploadFileUrl"]}/>
						</div>
						<div id="feedback_template_preview_content" dangerouslySetInnerHTML={{__html: messageContent}}/>
					</div>
				);
			} else {
				return(
					<div>
						<div id="feedback_template_preview_content" dangerouslySetInnerHTML={{__html: messageContent}}/>
						<div id="feedback_template_preview_content">
							<img style={style} src={p[p.prefix + "UploadFileUrl"]}/>
						</div>
					</div>
				);
			}
		}
		if(messageContent.startsWith("<div>")){
			messageContent = messageContent.replace("<div>","")
			let n = messageContent.lastIndexOf("</div>")
			if(n > 0){
				messageContent  = messageContent.substring(0, n);
			}
		}
		fileContent = fileContent.replace("[content]", messageContent);
		return (
			<div>
				<div id="feedback_template_preview_content" dangerouslySetInnerHTML={{__html: fileContent}}/>
			</div>
		);
	}
	handleHidden = () =>{
		this.setState({
			hidePreview: true,
		});
	}
	render = () =>{
		let p = this.props;
		let uploadUrl = p.uploadUrl + "/" + this.state[p.prefix + "UploadType"]
			+ "/" + p.prefix;
		let uploadedFile = "";
		if (p[p.prefix+"UploadID"] != ""){
			uploadedFile = I('Name of uploaded file') + ":" +
				p[p.prefix+"UploadFileName"];
		}
		let previewModal = (<div></div>);
		if (this.state.hidePreview == false){
			let previewBody = this.getPreviewBody();
			previewModal  = (
				<BootstrapModal
					isDraggable = {true}
					centerDialog={true}
					draggableHandle=".modal-header, .modal-body .reply-subject"
					onHidden={this.handleHidden}
					height="auto"
					width="635px"
					id="feedback_template_preview_modal"
					title="Preview">
					{previewBody}
				</BootstrapModal>

			);
		}
		return(<div className="col-lg-12 col-sm-4" style={{border: '1px solid #f0ad4e'}}>
			<div className="form-group-org">
				<label className="col-lg-2 col-sm-1">{I("Message")}:</label>
				<div className="col-lg-10 col-sm-4">
					<Ckeditor
						id={"Message_" + this.props.tab}
						ref={"Message_" + this.props.tab}
						locationHash={window.location.hash}
						defaultFontFamily={p.features["rich-text.default-font-family"]}
						defaultFontSize={p.features["rich-text.default-font-size"]}
						defaultContent={p.body}
						spellLanguages={p.languages}
						showResize={false}
						simpleToolbar={false}
						fileArchiveImages={p.archiveImages}
						onChange={this.handleCkeditorChange}
						onKeydown={this.handleKeyDown}
					/>
				</div>
			</div>
			<div className="form-group-org">
				<label className="col-lg-3 col-sm-1">{I("Upload Type")}:</label>
				<div className="col-lg-3 col-sm-4">
					<select name={p.prefix + "UploadType"} value={p[p.prefix + "UploadType"]} onChange={this.handleInputChange} className="form-control input-sm">
						<option value="0">{I("Basic")}</option>
						<option value="1">{I("Advanced")}</option>
					</select>
				</div>
				{!this.state[p.prefix + "UploadType"] && <div className="form-group-org">
						<div className="col-lg-10 col-sm-1">
							<label className="col-lg-6 col-sm-1">{I("Image position")}:</label>
							<div className="col-lg-4 col-sm-4">
								<select name={p.prefix + "ImagePosition"} value={p[p.prefix + "ImagePosition"]} onChange={this.handleInputChange} className="form-control input-sm">
									<option value="0">{I("Top")}</option>
									<option value="1">{I("Bottom")}</option>
								</select>
							</div>
						</div>
					</div>}
				<div className="col-lg-1 col-sm-4">
					<CHelper previewMessage={I("To upload image, choose basic type.")
											+ "<br />"
											+ I("To upload template, please choose advanced type.")
										} pos="right"/></div>
				<div className="col-lg-1 col-sm-4"></div>
			</div>
			<div className="form-group-org">
				<label className="col-lg-3 col-sm-1">{I("Upload file")}:</label>
				<div className="col-lg-3 col-sm-4">
					<FileUploader uploadTo={uploadUrl}
						onFileupload={p.handleFileUpload} />
				</div>
				<div className="col-lg-4 col-sm-4"><button className="btn btn-warning btn-sm" type="button" onClick={this.preview}>{I("Preview")}</button></div>
				<div className="col-lg-2 col-sm-4">
					<CHelper previewMessage={I("For Advanced type, please upload template for Thank you page.")
											+ "<br />"
											+ I("Your template must be named index.html and put in a zip archive.")
											+ "<br />"
											+ I("Please insert [content] tag in the template where the thank you message from the below message box should be shown.")
											+ "<br />"
											+ I("For Basic type, please upload a header logo for Thank you page.")
										} pos="right"/>
				</div>
			</div>
			<div>
				{ uploadedFile.length > 0 &&
					<label className="col-lg-3 col-sm-1">
						{uploadedFile}
						<i onClick={() => p.handleRemoveFile(p.prefix)} className="glyphicon glyphicon-remove" ></i>
					</label>
				}
				{previewModal}
			</div>
			{this.props.tab === CONTACTME && <div>
				<div className="form-group-org"></div>
				<div className="form-group-org">
					<label className="col-lg-3 col-sm-1">{I("Text for contact by Email")}:</label>
					<div className="col-lg-4 col-sm-4">
						<input name="contactByEmail" value={p.contactByEmail} className="form-control input-sm" type="text" onChange={this.handleInputChange} />
					</div>
					<div className="col-lg-1 col-sm-4"></div>
				</div>
				<div className="form-group-org">
					<label className="col-lg-3 col-sm-1">{I("Text for contact by phone")}:</label>
					<div className="col-lg-4 col-sm-4">
						<input name="contactByPhone" className="form-control input-sm" type="text" value={p.contactByPhone} onChange={this.handleInputChange} />
					</div>
					<div className="col-lg-1 col-sm-4"></div>
				</div>
				<div className="form-group-org">
					<label className="col-lg-3 col-sm-1">{I("Contact Button Text")}:</label>
					<div className="col-lg-4 col-sm-4">
						<input name="contactButton" className="form-control input-sm" type="text" value={p.contactButton} onChange={this.handleInputChange} />
					</div>
					<div className="col-lg-1 col-sm-4"></div>
				</div>
				<div className="form-group-org">
					<label className="col-lg-3 col-sm-1">{I("Area for replies")}:</label>
					<div className="col-lg-4 col-sm-4">
						<select className="form-control input-sm" name={"errandContainarea"} value={p.errandContainarea} onChange={this.handleInputChange}>
						{p.areaForReplies && p.areaForReplies.map((a, i) =><option key={i} value={a.id}>{a.value}</option>)}
						</select>
					</div>
					<div className="col-lg-1 col-sm-4"></div>
				</div>
			</div>}
		</div>)
	}
}
